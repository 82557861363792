import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";
import { Button, Select, Table, Modal, Input, message, Col, Row, DatePicker, InputNumber } from "antd";
import axios from "axios";
import $ from "jquery";
import qs from "qs";
import PageControl from "../../modules/common/PageControl";
import { connect } from "react-redux";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;

class OperationLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			key_word: "",
			type_id: "",
			date_min: "",
			date_max: "",
			ip: "",
			name: "",
			size: 10,
			current: 1,
			totalCount: 0,
			types: [],
			peoples: [],
			dataSet: [],
		};
	}

	componentDidMount() {
		this.fetchMsgList();
		this.getType();
		this.getPeople();
	}

	handleQuery = () => {
		this.setState({ current: 1 }, () => {
			this.fetchMsgList();
		});
	};

	fetchMsgList = () => {
		const { key_word, date_min, date_max, type_id, ip, name, size, current } = this.state;
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token } = this.props;
		const body = {
			page: current,
			count: size,
			keyword: key_word,
			date1: date_min,
			date2: date_max,
			ip: ip,
			name: name,
			log_type: type_id,
			tb_name: "log_admin",
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/admin.php?cmd=admin_log&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					let data = res.data.data.content.map((item, index) => {
						return {
							id: item.id,
							date: item.gmt_creat,
							log_ip: item.log_ip,
							type: item.log_type,
							person: item.name,
							content: item.log_content,
						};
					});
					this.setState({
						dataSet: data,
						totalCount: res.data.data.allcount,
					});
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	getType = () => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token } = this.props;
		const body = "user_type=admin";
		axios
			.post("https://api.sanfeng9.com/admin/admin.php?cmd=get_log_type&token=" + token, body, config)
			.then((res) => {
				if (res.data) {
					let data = res.data.map((item) => {
						return {
							name: item.data_value,
							id: item.data_value,
						};
					});
					this.setState({
						types: data,
					});
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	getPeople = () => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token } = this.props;
		const body = "tb_name=log_admin&user_type=admin";
		axios
			.post("https://api.sanfeng9.com/admin/admin.php?cmd=admin_list&token=" + token, body, config)
			.then((res) => {
				if (res.data) {
					let data = res.data.map((item) => {
						return {
							name: item.name,
							id: item.name,
						};
					});
					this.setState({
						peoples: data,
					});
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	parseColumn = () => {
		return [
			{
				title: "操作时间",
				dataIndex: "date",
				width: "300px",
				key: "date",
			},
			{
				title: "IP地址",
				dataIndex: "log_ip",
				width: "300px",
				key: "log_ip",
			},
			{
				title: "操作类型",
				dataIndex: "type",
				width: "300px",
				key: "type",
			},
			{
				title: "操作人员",
				dataIndex: "person",
				width: "300px",
				key: "person",
			},
			{
				title: "操作内容",
				dataIndex: "content",
				key: "content",
			},
		];
	};

	handleChangeDate = (data, dataString) => {
		this.setState(
			{
				date_min: dataString[0],
				date_max: dataString[1],
			},
			function () {
				this.handleQuery();
			}
		);
	};

	render() {
		let { key_word, type_id, types, peoples, ip, name, dataSet, current, size, totalCount } = this.state;
		return (
			<div className='page'>
				<div className='page-content'>
					<div className='content-header'>
						<h1>综合查询</h1>
					</div>
					<div className='box'>
						<div className='box-header'>
							<Row type='flex' justify='space-around' align='top'>
								<Col>
									<div className='common-select staticSelect'>
										<div className='label'>关键字：</div>
										<Input
											value={key_word}
											placeholder='请输入关键字'
											onChange={(e) => {
												this.setState({ key_word: e.target.value }, () => this.handleQuery());
											}}
										/>
									</div>
									<div className='common-select staticSelect rangepick'>
										<div className='label'>操作日期：</div>
										<RangePicker
											ranges={{
												本月: [moment().startOf("month"), moment().endOf("month")],
												本周: [moment().startOf("week"), moment().endOf("week")],
												上月: [
													moment().subtract(1, "months").startOf("month"),
													moment().subtract(1, "months").endOf("month"),
												],
												上周: [
													moment().subtract(1, "week").startOf("week"),
													moment().subtract(1, "week").endOf("week"),
												],
											}}
											allowClear={true}
											onChange={this.handleChangeDate}
											disabledDate={(current) => {
												return current && current > moment().add(1, "d");
											}}
										/>
									</div>
									<div className='common-select staticSelect' style={{ flexBasis: "24%" }}>
										<div className='label'>操作类型：</div>
										<Select
											placeholder='操作类型'
											allowClear
											value={type_id || undefined}
											onChange={(e) => {
												this.setState({ type_id: e }, () => this.handleQuery());
											}}>
											{types.map((item, index) => (
												<Option key={index} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									</div>
									<div className='common-select staticSelect'>
										<div className='label'>IP地址：</div>
										<Input
											value={ip}
											placeholder='请输入IP地址'
											onChange={(e) => {
												this.setState({ ip: e.target.value }, () => this.handleQuery());
											}}
										/>
									</div>
									<div className='common-select staticSelect'>
										<div className='label'>操作人员：</div>
										<Select
											placeholder='操作人员'
											allowClear
											value={name || undefined}
											onChange={(e) => {
												this.setState({ name: e }, () => this.handleQuery());
											}}>
											{peoples.map((item, index) => (
												<Option key={index} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									</div>
								</Col>
							</Row>
						</div>
						<div className='box-body'>
							<Table
								size='middle'
								pagination={false}
								dataSource={dataSet}
								columns={this.parseColumn()}
								rowKey={(e) => {
									return e.id;
								}}
								bordered
							/>
						</div>
						<div className='box-footer'>
							<PageControl
								current={current}
								pageSize={size}
								total={totalCount}
								toPage={(a) => {
									this.setState({ current: a }, () => {
										this.fetchMsgList();
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(OperationLog);
