import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, message,Modal } from "antd";
import axios from "axios";
import qs from "qs";
const {confirm} = Modal
export class TemplateList extends Component {
    state = {
        _TemplateList: [],
    };
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
    GetDatatList = () => {
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const token = this.props.token;
        const body = {
            user_type: 'admin',
            id: "",
        };
        axios
            .post(
                "https://api.sanfeng9.com/shop/weixin.php?cmd=wx_msg_template&token=" +
                token,
                qs.stringify(body),
                config
            )
            .then((res) => {
                if (res.data.response === "200") {
                    this.setState({
                        _TemplateList: res.data.msg,
                    });
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    handleDelete = (id) => {
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const token = this.props.token;
        const body = {
            user_type: 'admin',
            id: id,
        };
        axios
            .post(
                "https://api.sanfeng9.com/shop/weixin.php?cmd=template_delete&token=" +
                token,
                qs.stringify(body),
                config
            )
            .then((res) => {
                if (res.data.response === "200") {
                    message.success(res.data.msg);
                    this.GetDatatList();
                } else {
                    message.info(res.data.msg);
                }
            });
    };
    confirmDelete = (id) =>{
        let _this = this;
        confirm({
            title: "删除模板",
            content: "数据删除后不能恢复，您是否确认删除！",
            okText: "确定",
            cancelText: "取消",
            onOk() {
                _this.handleDelete(id);
            },
            onCancel() {},
        });
    };
    ChangeShowStatus = (status, id) => {
        console.log(status);
        console.log(id);
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const token = this.props.token;
        const body = {
            user_type: 'admin',
            id: id,
            is_show: 1 - status,
        };
        axios
            .post(
                "https://api.sanfeng9.com/shop/weixin.php?cmd=template_is_show&token=" +
                token,
                qs.stringify(body),
                config
            )
            .then((res) => {
                if (res.data.response === "200") {
                    message.success(res.data.msg);
                    this.GetDatatList();
                } else {
                    message.warning(res.data.msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    ChangeTop = (id) => {
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const token = this.props.token;
        const body = {
            user_type: 'admin',
            id: id,
        };
        axios
            .post(
                "https://api.sanfeng9.com/shop/weixin.php?cmd=template_top&token=" +
                token,
                qs.stringify(body),
                config
            )
            .then((res) => {
                if (res.data.response === "200") {
                    message.success(res.data.msg);
                    this.GetDatatList();
                } else {
                    message.warning(res.data.msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const new_type = nextProps.type;
        this.GetDatatList();
    }
    componentDidMount() {
        this.GetDatatList();
    }
    renderList = (t) => {
        return (
            <div className="choose-show-template">
                {t.map((item, index) => (
                    <div className="item" key={"sanfeng-template" + index}>
                        <div className="cover">
                            <img src={item.cover} alt="" />
                            <div className="edit">
                                <div
                                    className="edit-content"
                                    onClick={() =>
                                        this.props.handleEdit(item.id)
                                    }
                                >
									<span className="icon iconfont edit-icon">
										&#xe60c;
									</span>
                                    <span className="edit-text">
										编辑此模板
									</span>
                                </div>
                            </div>
                        </div>
                        <div className="operation">
                            <div className="normal">
                                <div className="title">
                                    <div className="name">{item.name}</div>
                                </div>
                            </div>
                            <div className="handle">
                                {/* {item.is_show === "1" ? (
									<Button
										size="small"
										className="unpublish"
										onClick={() =>
											this.ChangeShowStatus(
												item.is_show,
												item.id
											)
										}
									>
										撤销发布
									</Button>
								) : (
									<Button
										size="small"
										className="publish"
										onClick={() =>
											this.ChangeShowStatus(
												item.is_show,
												item.id
											)
										}
									>
										点击发布
									</Button>
								)}
								<Button size="small" className="edit">
									删除
								</Button> */}
                                {item.is_show === "1" ? (
                                    <span
                                        className="unpublish"
                                        onClick={() =>
                                            this.ChangeShowStatus(
                                                item.is_show,
                                                item.id
                                            )
                                        }
                                    >
										撤销发布
									</span>
                                ) : (
                                    <span
                                        className="publish"
                                        onClick={() =>
                                            this.ChangeShowStatus(
                                                item.is_show,
                                                item.id
                                            )
                                        }
                                    >
										点击发布
									</span>
                                )}
                                <span
                                    className="unpublish"
                                    onClick={() => {
                                        this.ChangeTop(item.id)
                                    }}
                                >
										置顶
									</span>
                                <div>
                                    <span
                                        className="delete-item"
                                        onClick={() => this.confirmDelete(item.id)}
                                    >
										删除
									</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    render() {
        return (
            <div className="box-body">
                <Row style={{ marginTop: "-10px" }}>
                    <Col>
                        <Button
                            type="primary"
                            onClick={() => this.props.handleEdit("")}
                        >
                            添加模板
                        </Button>
                    </Col>
                </Row>
                <div className="template-list" style={{ marginTop: "14px" }}>
                    <div className="template-list-body">
                        <div className="container">
                            {this.renderList(this.state._TemplateList)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
});
export default connect(mapStateToProps)(TemplateList);
