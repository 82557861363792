import axios from "axios";
import qs from "qs";
export const getWxList = () => (dispatch, getState) => {
	const config = {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
	};
	const token = getState().auth.token;
	axios
		.post(
			"https://api.sanfeng9.com/admin/admin.php?cmd=admin_list&token=" + token,
			qs.stringify({ user_type: "admin" }),
			config
		)
		.then((res) => {
			if (res.data.response === "200") {
				let content = res.data.data.content,
					_list = [];

				_list = content.map((item) => {
					return { id: item.id, realname: item.realname, online: item.online, groupname: item.groupname };
				});
				dispatch({
					type: "SET_WXLIST",
					payload: _list,
				});
			} else {
				console.log(res.data.msg);
			}
		});
};
