import { combineReducers } from "redux";
import auth from "./auth";
import menu from "./menu";
import wechat from "./wechat";

export default combineReducers({
	auth,
	menu,
	wechat,
});
