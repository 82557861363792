import React, { Component } from "react";
import { Col, DatePicker, Form, Input, InputNumber, message, Modal, Radio, Row, Select, Avatar } from "antd";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import qs from "qs";

const { Search } = Input;

export class OrganizationEditModal extends Component {
	state = {
		classRooms: [],
		courses: [],
		teachers: [],
		info: this.props.info,
		teacherList: [],
		visible: false,
		manageData: [],
	};
	static propTypes = {
		onHandleCancel: PropTypes.func,
		onHandleOk: PropTypes.func,
		onSelectColumns: PropTypes.func,
		onClick: PropTypes.func,
		token: PropTypes.string,
		visible: PropTypes.bool,
		isEdit: PropTypes.bool,
		info: PropTypes.object,
	};

	componentDidMount() {}

	componentWillMount() {
		this.getManageData();
	}

	/*componentWillReceiveProps(nextProps) {
        const { info } = nextProps;
        if(info.id){
            const data = {
                id:info.id,
                head_img:info.head_img,
                name:info.name,
                remarkCode:info.remarkCode,
                phone:info.phone,
                gender:info.gender,
                birthday:info.birthday,
                remark:info.remark
            }
            console.log(data)
            this.setState({info:data})
        }

    }*/

	getManageData = () => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const token = this.props.token;
		axios
			.post("https://api.sanfeng9.com/admin/admin.php?cmd=admin_list&token=" + token, "user_type=admin", config)
			.then((res) => {
				const data = res.data.data.content.map((item) => ({
					key: item.id,
					id: item.id,
					name: item.realname,
					activeTime: item.last_time,
					ip: item.last_ip,
					ipAddr: item.ip_addr,
					group: item.groupname,
					phone: item.phone,
					qq: item.qq,
					superAdmin: item.super_admin,
				})); /*.filter((item)=>{
                    return item.group === '销售'
                });*/
				this.setState({
					manageData: data,
				});
				let findId = data.find((item) => {
					return item.id == this.state.info.sale_user_id;
				});
				if (!findId) {
					this.setState({
						info: Object.assign({}, this.state.info, {
							sale_user_id: "",
						}),
					});
				}
			});
	};

	handleOk = (e) => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token, match } = this.props;
		const { info } = this.state;
		let shop_level, ticheng, sale_level;

		if (info.ticheng === "低提成") {
			ticheng = 100;
		}
		if (info.ticheng === "高提成") {
			ticheng = 200;
		}
		if (info.sale_level === "待沟通") {
			sale_level = 100;
		}
		if (info.sale_level === "沟通结束") {
			sale_level = 200;
		}
		const body = {
			id: info.id,
			name: info.name,
			shop_level: info.shop_level,
			end_time: info.end_time,
			/*sale_user_id: info.sale_user_id,
			ticheng: ticheng,
			sale_level: sale_level,*/
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_modify&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					message.success(res.data.msg);
					this.props.onFetchMsgList();
					setTimeout(() => {
						this.props.onHandleCancel();
					}, 100);
				} else {
					message.error(res.data.msg);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render() {
		const InputGroup = Input.Group;
		const { TextArea } = Input;
		const { Option } = Select;
		const { info, teachers, teacherList, classRooms, courses, manageData } = this.state;
		const options1 = [
			{
				key: "1",
				name: "硬笔书法",
				value: "硬笔书法",
			},
			{
				key: "2",
				name: "小学编程",
				value: "小学编程",
			},
		];
		const formItemLayout = {
			labelCol: {
				span: 6,
			},
			wrapperCol: {
				span: 18,
			},
		};
		const children = [];
		teachers.map((item) => {
			children.push(<Option key={item.teacherId}>{item.name}</Option>);
		});
		return (
			<Modal
				title='编辑商户信息'
				visible={true}
				okText='确定'
				cancelText='取消'
				onOk={this.handleOk}
				onCancel={this.props.onHandleCancel}
				style={{ width: 520 }}>
				<Form {...formItemLayout}>
					<Form.Item label='商户名称'>
						<Input
							id='name'
							maxLength={40}
							name='info.name'
							placeholder='请输入'
							type='text'
							onChange={(e) => {
								this.setState({
									info: Object.assign({}, info, {
										name: e.target.value,
									}),
								});
							}}
							value={info.name}
							style={{ width: 250 }}
						/>
					</Form.Item>
					<Form.Item label='到期时间'>
						<DatePicker
							placeholder='请选择日期'
							locale={locale}
							value={info.end_time ? moment(info.end_time, "YYYY-MM-DD") : null}
							onChange={(value, dateString) => {
								this.setState({
									info: Object.assign({}, info, {
										end_time: dateString,
									}),
								});
							}}
						/>
					</Form.Item>
					<Form.Item label='商户类型'>
						<Select
							placeholder='请选择类型'
							value={info.shop_level || undefined}
							onChange={(e) => {
								this.setState({
									info: Object.assign({}, info, { shop_level: e }),
								});
							}}
							style={{ width: 250 }}>
							<Option key='0' value='10'>
								永久免费版
							</Option>
							<Option key='1' value='100'>
								单门店版
							</Option>
							<Option key='2' value='500'>
								五门店版
							</Option>
							<Option key='3' value='999'>
								不限门店版
							</Option>
						</Select>
					</Form.Item>
					{/*<Form.Item label='客户经理'>
						<Select
							placeholder='请选择经理'
							value={info.sale_user_id || undefined}
							onChange={(e) => {
								this.setState({
									info: Object.assign({}, info, { sale_user_id: e }),
								});
							}}
							style={{ width: 250 }}>
							{manageData.map((item, index) => (
								<Option key={index} value={item.id}>
									{item.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label='销售提成'>
						<Select
							placeholder='请选择提成'
							value={info.ticheng || undefined}
							onChange={(e) => {
								this.setState({
									info: Object.assign({}, info, { ticheng: e }),
								});
							}}
							style={{ width: 250 }}>
							<Option key='1' value='低提成'>
								低提成
							</Option>
							<Option key='2' value='高提成'>
								高提成
							</Option>
						</Select>
					</Form.Item>*/}
				</Form>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(OrganizationEditModal);
