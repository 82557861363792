import React, { Component } from "react";
import { Button, Select, Table, Modal, Form, message, Col, Row, Spin, Breadcrumb, Tabs, Icon, DatePicker } from "antd";
import { Link, Switch, Route } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import "./DetailRecord.less";
import { createHashHistory } from "history";
import AddRecordModal from "./AddRecordModal";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { connect } from "react-redux";

const { confirm } = Modal;

class DetailBranch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataSet: [],
			modal: null,
		};
	}

	componentDidMount() {
		this.fetchMsgList();
	}

	fetchMsgList = () => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token, match, shopId } = this.props;
		const body = {
			shop_id: shopId,
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_branch_list&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					let data = res.data.data.content;
					this.setState({
						dataSet: data,
					});
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	deleteConfirm = (id) => {
		let _this = this;
		confirm({
			title: "删除",
			content: "确定删除此门店吗",
			okText: "确定",
			cancelText: "取消",
			onOk() {
				_this.delete(id);
			},
			onCancel() {},
		});
	};
	delete = (id) => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token, shopId } = this.props;
		const body = {
			brand_id: id,
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_branch_del&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					message.success(res.data.msg);
					this.fetchMsgList();
				} else {
					Modal.warning({
						title: "提示信息",
						content: res.data.msg,
						maskClosable: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	changeEndTime = (id, end_time) => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token, shopId } = this.props;
		const body = {
			brand_id: id,
			end_time: end_time,
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_branch_modify&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					message.success(res.data.msg);
					this.fetchMsgList();
				} else {
					Modal.warning({
						title: "提示信息",
						content: res.data.msg,
						maskClosable: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	handleAdd = () => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const token = this.props.token;

		const body = {
			shop_id: this.props.shopId,
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_branch_add&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					message.success(res.data.msg);
					this.fetchMsgList();
				} else {
					message.error(res.data.msg);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	handleEdit = () => {
		const { token, match, shopId } = this.props;
		let modal = (
			<AddRecordModal
				shopId={shopId}
				onHandleOk={() => {
					this.setState({ visible: false });
				}}
				onHandleCancel={() => {
					this.setState({ modal: null });
				}}
				onFetchMsgList={this.fetchMsgList}
			/>
		);
		this.setState({ modal });
	};

	render() {
		const { match } = this.props;
		const { mode, dataSet } = this.state;
		const columns = [
			{
				title: "门店名称",
				dataIndex: "name",
				key: "name",
			},
			{
				title: "开通时间",
				dataIndex: "gmt_creat",
				key: "gmt_creat",
			},
			// {
			// 	title: "到期时间",
			// 	dataIndex: "end_time",
			// 	key: "end_time",
			// 	render: (text, record, index) => (
			// 		<>
			// 			{record.end_time_state ? (
			// 				<DatePicker
			// 					value={moment(text)}
			// 					onChange={(date, dateString) => {
			// 						dataSet[index].end_time = dateString;
			// 						this.setState({ dataSet });
			// 					}}
			// 				/>
			// 			) : (
			// 				<span>{text}</span>
			// 			)}
			// 			{record.end_time_state ? (
			// 				<span
			// 					onClick={() => {
			// 						console.log(moment(record.end_time).valueOf());
			// 						console.log(moment().endOf("day").valueOf() + 1);
			// 						if (moment(record.end_time).valueOf() > moment().endOf("day").valueOf()) {
			// 							this.changeEndTime(record.id, record.end_time);
			// 						} else {
			// 							message.warning("到期时间应大于当前日期");
			// 						}
			// 					}}
			// 					style={{ marginLeft: "8px", cursor: "pointer", color: "#008EEC" }}>
			// 					保存
			// 				</span>
			// 			) : (
			// 				<span
			// 					onClick={() => {
			// 						dataSet[index].end_time_state = true;
			// 						this.setState({ dataSet });
			// 					}}
			// 					style={{ marginLeft: "8px", cursor: "pointer", color: "#008EEC" }}>
			// 					编辑
			// 				</span>
			// 			)}
			// 		</>
			// 	),
			// },
			// {
			// 	title: "学员数量",
			// 	dataIndex: "student_num",
			// 	key: "student_num",
			// },
			// {
			// 	title: "老师数量",
			// 	dataIndex: "teacher_num",
			// 	key: "teacher_num",
			// },
			// {
			// 	title: "本月收入",
			// 	dataIndex: "income_month",
			// 	key: "income_month",
			// },
			// {
			// 	title: "本月开支",
			// 	dataIndex: "cost_month",
			// 	key: "cost_month ",
			// },
			{
				title: "操作",
				dataIndex: "operate",
				render: (text, record) => (
					<span onClick={this.deleteConfirm.bind(this, record.id)} style={{ cursor: "pointer", color: "#008EEC" }}>
						删除
					</span>
				),
			},
		];
		return (
			<div className='student-signed-class'>
				<div className='signed-header' style={{ overflow: "hidden" }}>
					<Button type='primary' onClick={this.handleAdd}>
						添加
					</Button>
				</div>
				<Table rowKey={(record) => record.id} dataSource={dataSet} columns={columns} bordered pagination={false} />
				{this.state.modal}
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(DetailBranch);
