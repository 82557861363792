import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Tabs } from "antd";
import { createHashHistory } from 'history';
import {connect} from "react-redux";
import Content from "../../modules/help/Content"
import Type from "../../modules/help/Type"
import Add from "../../modules/help/Add"
import DetailRecord from "../../modules/organization/DetailBranch";

const { TabPane } = Tabs;
const hashHistory = createHashHistory()

export class Help extends Component {


    state={ tab: "content" };

    componentWillMount() {
        const { location, match } = this.props;
        if(location.pathname === `${match.url}/content`){
            this.setState({
                tab:'content'
            })
        }
        if(location.pathname === `${match.url}/type`){
            this.setState({
                tab:'type'
            })
        }
        if(location.pathname === `${match.url}/add`){
            this.setState({
                tab:'add'
            })
        }
    }


    render() {
        const match = this.props.match;
        console.log(match)
        const tab = this.state.tab || "content";
        return (
            <div className="page">
                <div className="content-header">
                    <h1>帮助信息</h1>
                </div>
                <div className="box" style={{ paddingTop: 12 }}>
                    <Tabs key="tabs" activeKey={tab} onChange={(a)=>{
                        hashHistory.push(match.url + "/" + a);
                        this.setState({tab:a})
                    }}>
                        <TabPane tab="内容列表" key="content">

                        </TabPane>
                        <TabPane tab="类别管理" key="type">

                        </TabPane>
                        <TabPane tab="内容添加" key="add">

                        </TabPane>
                    </Tabs>
                    <Switch>
                        <Route path={match.url + "/content"} render={() => (
                            <Content
                                handleEdit={()=>{
                                    this.setState({
                                        tab:'add'
                                    })
                                }}
                            />
                        )} />

                        <Route path={match.url + "/type"} component={Type} />
                        <Route path={match.url + "/add"} render={() => (
                            <Add
                                handleEdit={()=>{
                                    this.setState({
                                        tab:'content'
                                    })
                                }}
                            />
                        )} />
                        <Redirect to={`${match.url}/content`} /> {/* 預設頁面 */}
                    </Switch>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    token: state.auth.token,
});
export default connect(
    mapStateToProps
)(Help);
