import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import {
  Button,
  Select,
  Table,
  Modal,
  Input,
  message,
  Col,
  Row,
  DatePicker,
  InputNumber,
  Switch,
} from "antd";
import axios from "axios";
import qs from "qs";

import { connect } from "react-redux";
import "./index.less";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      app_ver_android: "",
      app_ver_android_state: false,
      app_content_android: "",
      app_content_android_state: false,
      app_renew_android: "",
      app_renew_android_state: false,
      app_down_android: "",
      app_down_android_state: false,
      app_ver_apple: "",
      app_ver_apple_state: false,
      app_content_apple: "",
      app_content_apple_state: false,
      app_renew_apple: "",
      app_renew_apple_state: false,
    };
  }

  componentDidMount() {
    this.GetInfo();
  }

  GetInfo = () => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const { token } = this.props;

    axios
      .post(
        "https://api.sanfeng9.com/shop/public.php?cmd=app_ver_check&token=" +
          token,
        "user_type=admin",
        config
      )
      .then((res) => {
        if (res.data.response == 200) {
          const {
            app_android_ver_txt,
            app_android_ver_force_renew,
            app_apple_ver_txt,
            app_apple_ver_force_renew,
          } = res.data;
          this.setState({
            app_down_android: res.data.app_down_android,
            app_ver_android: res.data.app_ver_android,
            app_ver_apple: res.data.app_ver_apple,
            app_content_android: app_android_ver_txt,
            app_renew_android: app_android_ver_force_renew,
            app_content_apple: app_apple_ver_txt,
            app_renew_apple: app_apple_ver_force_renew,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  AndroidVer = () => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const { token } = this.props;
    const query = {
      app_ver_android: this.state.app_ver_android,
      user_type: "admin",
    };
    axios
      .post(
        "https://api.sanfeng9.com/admin/main.php?cmd=app_ver_set&token=" +
          token,
        qs.stringify(query),
        config
      )
      .then((res) => {
        if (res.data.response == 200) {
          message.success("提交成功");
          this.GetInfo();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  AndroidDown = () => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const { token } = this.props;
    const query = {
      app_down_android: this.state.app_down_android,
      user_type: "admin",
    };
    axios
      .post(
        "https://api.sanfeng9.com/admin/main.php?cmd=app_ver_set&token=" +
          token,
        qs.stringify(query),
        config
      )
      .then((res) => {
        if (res.data.response == 200) {
          message.success("提交成功");
          this.GetInfo();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  AppleVer = () => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const { token } = this.props;
    const query = {
      app_ver_apple: this.state.app_ver_apple,
      user_type: "admin",
    };
    axios
      .post(
        "https://api.sanfeng9.com/admin/main.php?cmd=app_ver_set&token=" +
          token,
        qs.stringify(query),
        config
      )
      .then((res) => {
        if (res.data.response == 200) {
          message.success("提交成功");
          this.GetInfo();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  Android = (type) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const { token } = this.props;
    const query = {
      user_type: "admin",
    };
    switch (type) {
      case "app_content_android":
        query.app_android_ver_txt = this.state.app_content_android;
        break;
      case "app_renew_android":
        query.app_android_ver_force_renew = this.state.app_renew_android;
        break;
      case "app_content_apple":
        query.app_apple_ver_txt = this.state.app_content_apple;
        break;
      case "app_renew_apple":
        query.app_apple_ver_force_renew = this.state.app_renew_apple;
        break;
      default:
        break;
    }
    axios
      .post(
        "https://api.sanfeng9.com/admin/main.php?cmd=app_ver_set&token=" +
          token,
        qs.stringify(query),
        config
      )
      .then((res) => {
        if (res.data.response == 200) {
          message.success("提交成功");
          this.GetInfo();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      app_ver_android,
      app_ver_android_state,
      app_content_android,
      app_content_android_state,
      app_renew_android,
      app_renew_android_state,
      app_down_android,
      app_down_android_state,
      app_ver_apple,
      app_ver_apple_state,
      app_content_apple,
      app_content_apple_state,
      app_renew_apple,
      app_renew_apple_state,
    } = this.state;

    return (
      <div className="page">
        <div className="page-content">
          <div className="content-header">
            <h1>APP设置</h1>
          </div>
          <div className="box">
            <div className="card">
              <div className="card_header android">
                <i className="icon iconfont">&#xe69f;</i>
                <span>安卓设置</span>
              </div>
              <div className="card_body">
                <div className="item">
                  <Row gutter={24}>
                    <Col span={6} push={1}>
                      版本信息：
                    </Col>
                    <Col span={14} pull={1}>
                      {app_ver_android_state ? (
                        <Input
                          value={app_ver_android}
                          style={{ width: 350 }}
                          onChange={(e) => {
                            this.setState({
                              app_ver_android: e.target.value,
                            });
                          }}
                        />
                      ) : (
                        <span>{app_ver_android}</span>
                      )}
                    </Col>
                    <Col span={4}>
                      <span
                        onClick={() => {
                          this.setState({
                            app_ver_android_state: !app_ver_android_state,
                          });
                        }}
                      >
                        {app_ver_android_state ? (
                          <span
                            onClick={this.AndroidVer}
                            className="icon iconfont"
                          >
                            &#xe680;
                          </span>
                        ) : (
                          <span className="icon iconfont">&#xe60c;</span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="item">
                  <Row gutter={24}>
                    <Col span={6} push={1}>
                      版本内容：
                    </Col>
                    <Col span={14} pull={1}>
                      {app_content_android_state ? (
                        <Input
                          value={app_content_android}
                          style={{ width: 350 }}
                          onChange={(e) => {
                            this.setState({
                              app_content_android: e.target.value,
                            });
                          }}
                        />
                      ) : (
                        <span>{app_content_android}</span>
                      )}
                    </Col>
                    <Col span={4}>
                      <span
                        onClick={() => {
                          this.setState({
                            app_content_android_state:
                              !app_content_android_state,
                          });
                        }}
                      >
                        {app_content_android_state ? (
                          <span
                            onClick={() => this.Android("app_content_android")}
                            className="icon iconfont"
                          >
                            &#xe680;
                          </span>
                        ) : (
                          <span className="icon iconfont">&#xe60c;</span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="item">
                  <Row gutter={24}>
                    <Col span={6} push={1}>
                      强制更新：
                    </Col>
                    <Col span={14} pull={1}>
                      {app_renew_android_state ? (
                        <Switch
                          checked={app_renew_android === "yes"}
                          onChange={(e) => {
                            const value = e ? "yes" : "no";
                            this.setState({
                              app_renew_android: value,
                            });
                          }}
                        />
                      ) : (
                        <span>{app_renew_android === "yes" ? "是" : "否"}</span>
                      )}
                    </Col>
                    <Col span={4}>
                      <span
                        onClick={() => {
                          this.setState({
                            app_renew_android_state: !app_renew_android_state,
                          });
                        }}
                      >
                        {app_renew_android_state ? (
                          <span
                            onClick={() => this.Android("app_renew_android")}
                            className="icon iconfont"
                          >
                            &#xe680;
                          </span>
                        ) : (
                          <span className="icon iconfont">&#xe60c;</span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="item">
                  <Row gutter={24}>
                    <Col span={6} push={1}>
                      下载地址：
                    </Col>
                    <Col span={14} pull={1}>
                      {app_down_android_state ? (
                        <Input
                          value={app_down_android}
                          style={{ width: 410 }}
                          onChange={(e) => {
                            this.setState({
                              app_down_android: e.target.value,
                            });
                          }}
                        />
                      ) : (
                        <span>{app_down_android}</span>
                      )}
                    </Col>
                    <Col span={4}>
                      <span
                        onClick={() => {
                          this.setState({
                            app_down_android_state: !app_down_android_state,
                          });
                        }}
                      >
                        {app_down_android_state ? (
                          <span
                            onClick={this.AndroidDown}
                            className="icon iconfont"
                          >
                            &#xe680;
                          </span>
                        ) : (
                          <span className="icon iconfont">&#xe60c;</span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card_header apple">
                <i className="icon iconfont">&#xe662;</i>
                <span>苹果设置</span>
              </div>
              <div className="card_body">
                <div className="item">
                  <Row gutter={24}>
                    <Col span={6} push={1}>
                      版本信息：
                    </Col>
                    <Col span={14} pull={1}>
                      {app_ver_apple_state ? (
                        <Input
                          value={app_ver_apple}
                          style={{ width: 350 }}
                          onChange={(e) => {
                            this.setState({
                              app_ver_apple: e.target.value,
                            });
                          }}
                        />
                      ) : (
                        <span>{app_ver_apple}</span>
                      )}
                    </Col>
                    <Col span={4}>
                      <span
                        onClick={() => {
                          this.setState({
                            app_ver_apple_state: !app_ver_apple_state,
                          });
                        }}
                      >
                        {app_ver_apple_state ? (
                          <span
                            onClick={this.AppleVer}
                            className="icon iconfont"
                          >
                            &#xe680;
                          </span>
                        ) : (
                          <span className="icon iconfont">&#xe60c;</span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="item">
                  <Row gutter={24}>
                    <Col span={6} push={1}>
                      版本内容：
                    </Col>
                    <Col span={14} pull={1}>
                      {app_content_apple_state ? (
                        <Input
                          value={app_content_apple}
                          style={{ width: 350 }}
                          onChange={(e) => {
                            this.setState({
                              app_content_apple: e.target.value,
                            });
                          }}
                        />
                      ) : (
                        <span>{app_content_apple}</span>
                      )}
                    </Col>
                    <Col span={4}>
                      <span
                        onClick={() => {
                          this.setState({
                            app_content_apple_state: !app_content_apple_state,
                          });
                        }}
                      >
                        {app_content_apple_state ? (
                          <span
                            onClick={() => this.Android("app_content_apple")}
                            className="icon iconfont"
                          >
                            &#xe680;
                          </span>
                        ) : (
                          <span className="icon iconfont">&#xe60c;</span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="item">
                  <Row gutter={24}>
                    <Col span={6} push={1}>
                      强制更新：
                    </Col>
                    <Col span={14} pull={1}>
                      {app_renew_apple_state ? (
                        <Switch
                          checked={app_renew_apple === "yes"}
                          onChange={(e) => {
                            const value = e ? "yes" : "no";
                            this.setState({
                              app_renew_apple: value,
                            });
                          }}
                        />
                      ) : (
                        <span>{app_renew_apple === "yes" ? "是" : "否"}</span>
                      )}
                    </Col>
                    <Col span={4}>
                      <span
                        onClick={() => {
                          this.setState({
                            app_renew_apple_state: !app_renew_apple_state,
                          });
                        }}
                      >
                        {app_renew_apple_state ? (
                          <span
                            onClick={() => this.Android("app_renew_apple")}
                            className="icon iconfont"
                          >
                            &#xe680;
                          </span>
                        ) : (
                          <span className="icon iconfont">&#xe60c;</span>
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.auth.token,
});
export default connect(mapStateToProps)(App);
