const initialState = {
	token: localStorage.getItem("c_token"),
	isAuthenticated: null,
	isLoading: false,
	user: null,
};
export default function (state = initialState, action) {
	switch (action.type) {
		case "USER_LOADING":
			return {
				...state,
				isLoading: true,
			};
		case "USER_UNTOKEN":
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
			};
		case "USER_LOADED":
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				user: action.payload,
			};
		case "RESET_USERINFO":
			return {
				...state,
				user: action.payload,
			};
		case "LOGIN_SUCCESS":
		case "REGISTER_SUCCESS":
			localStorage.setItem("c_token", action.payload.token);
			return {
				...state,
				token: action.payload.token,
				isAuthenticated: true,
				isLoading: false,
			};
		case "AUTH_ERROR":
		case "LOGIN_FAIL":
		case "LOGOUT_SUCCESS":
		case "REGISTER_FAIL":
			localStorage.removeItem("c_token");
			return {
				...state,
				token: null,
				user: null,
				isAuthenticated: false,
				isLoading: false,
			};
		default:
			return state;
	}
}
