import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";
import { Button, Select, Table, Modal, Input, message, Col, Row, DatePicker, InputNumber  } from "antd";
import axios from 'axios'
import $ from "jquery"
import qs from 'qs'
import OrganizationManageDetail from "../organization/OrganizationManageDetail"
import PageControl from "../common/PageControl"
import "../common/StaticSelect.less"
import {connect} from "react-redux";
import moment from 'moment';
import {UserInfo} from "../../components/home/UserInfo";
const { Option } = Select;
const { RangePicker } = DatePicker;

class FinanceManage extends Component {
    constructor(props){
        super(props);
        this.state = {
            dataSet: [],
            query: {
                page: 1,
                count: 10,
                caiwu_type: '',
                caiwu_money_1: '',
                caiwu_money_2: '',
                caiwu_time_1: '',
                caiwu_time_2: '',
                cost_type: '',//消费类型
                income_type: '',//收入类型
                remark: '',//收入类型
                user_type:"admin"
            },
            totalCount:0,
            manageData:[]
        }
    }

    componentDidMount() {
        this.fetchMsgList()
    }

    handleQuery = () => {
        this.setState({
            query:Object.assign({},this.state.query,{page:1})
        },()=>this.fetchMsgList())
    };

    fetchMsgList = () => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props;
        const { query } = this.state;
        axios
            .post("https://api.sanfeng9.com/admin/caiwu.php?cmd=shop_caiwu_list&token="+token, qs.stringify(query), config)
            .then(res => {
                if(res.data.response == 200){
                    let data = res.data.data.content.map((item,index)=>{
                        return{
                            id:item.id,
                            shopId:item.shop_id,
                            name:item.name,
                            caiwu_type: item.caiwu_type,
                            caiwu_money: item.caiwu_money,
                            caiwu_time: item.caiwu_time,
                            gmt_creat: item.gmt_creat,
                            cost_type: item.cost_type,//消费类型
                            income_type: item.income_type,//收入类型
                            remark: item.remark,//备注
                            input_user: item.input_user,//收入类型
                        }
                    })
                    this.setState({
                        dataSet: data,
                        totalCount:res.data.data.allcount
                    });
                }else{

                }
            })
            .catch(err => {
                console.log(err)
            });
    };


    parseColumn = () => {
        const { match } = this.props;
        return [
            {
                title: "商户名称",
                dataIndex: "name",
                key: "name",
                render: (text, record) => (
                    <Link
                        style={{ color: "#ff7519" }}
                        to={{
                            pathname: `/integrated_query/detail/${record.shopId}/record`,
                            query: { record },
                        }}
                    >
                        {text}
                    </Link>
                ),
            },
            {
                title: "类型",
                dataIndex: "caiwu_type",
                key: "caiwu_type",
                width: "80px"
            },
            {
                title: "收入方式",
                dataIndex: "income_type",
                key: "income_type",
                width: "100px"
            },
            {
                title: "消费类型",
                dataIndex: "cost_type",
                key: "cost_type",
                width: "100px"
            },
            {
                title: "操作时间",
                dataIndex: "gmt_creat",
                key: "gmt_creat",
            },
            {
                title: "入账时间",
                dataIndex: "caiwu_time",
                key: "caiwu_time",
            },
            {
                title: "备注",
                dataIndex: "remark",
                key: "remark",
            },
            {
                title: "金额",
                dataIndex: "caiwu_money",
                key: "caiwu_money",
                width: "100px"
            },
            {
                title: "录入人员",
                dataIndex: "input_user",
                key: "input_user",
                width: "100px"
            },
        ];
    };

    handleChangeDate = (dates,dateStrings) => {
        this.setState({
            query:Object.assign({},this.state.query,{
                caiwu_time_1:dateStrings[0],
                caiwu_time_2:dateStrings[1],
            })
        },()=>{
            this.handleQuery()
        })
    };

    render() {
        const { match } = this.props;
        const { query, totalCount, manageData } = this.state;
        const { page, count, caiwu_type, caiwu_money_1, caiwu_money_2, caiwu_time_1, caiwu_time_2, cost_type, income_type, remark, } = query;
        return (
            <div className="page">
                <div className="page-content">
                    <div className="content-header">
                        <h1>综合查询</h1>
                    </div>
                    <div className="box">
                        <div className="box-header">
                            <Row
                                type="flex"
                                justify="space-around"
                                align="top"
                            >
                                <Col>
                                    <div className="common-select staticSelect">
                                        <div className="label">财务类型：</div>
                                        <Select placeholder="请选择类型" allowClear value={caiwu_type || undefined} onChange={(e)=>{
                                            if(e == 1){
                                                this.setState({
                                                    query:Object.assign({},query,{caiwu_type:e,cost_type:''})
                                                },()=>{
                                                    this.handleQuery()
                                                })
                                            }else if(e == 0){
                                                this.setState({
                                                    query:Object.assign({},query,{caiwu_type:e,income_type:''})
                                                },()=>{
                                                    this.handleQuery()
                                                })
                                            }else{
                                                this.setState({
                                                    query:Object.assign({},query,{caiwu_type:e})
                                                },()=>{
                                                    this.handleQuery()
                                                })
                                            }
                                        }} >
                                            <Option key="1" value="1">收入</Option>
                                            <Option key="2" value="0">支出</Option>
                                        </Select>
                                    </div>
                                    <div className="common-select staticSelect rangepick">
                                        <div className="label">财务时间：</div>
                                        <RangePicker
                                            ranges={{
                                                '本月': [moment().startOf('month'), moment().endOf('month')],
                                                '本周': [moment().startOf('week'), moment().endOf('week')],
                                                '上月': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
                                                '上周': [moment().week(moment().week() - 1).startOf('week'), moment().week(moment().week() - 1).endOf('week')],
                                            }}
                                            allowClear={true}
                                            onChange={this.handleChangeDate}
                                            value={caiwu_time_1?[moment(caiwu_time_1),moment(caiwu_time_2)]:null}
                                            format="YYYY-MM-DD"
                                            disabledDate={(current) => {
                                                return current && current > moment().add(1, "d");
                                            }}
                                        />
                                    </div>
                                    <div className="common-select staticSelect">
                                        <div className="label">入账途径：</div>
                                        <Select placeholder="请选择途径" allowClear value={income_type || undefined} onChange={(e)=>{
                                            this.setState({
                                                query:Object.assign({},query,{income_type:e,cost_type:''})
                                            },()=>this.handleQuery())
                                        }} >
                                            <Option key="1" value="微信">微信</Option>
                                            <Option key="2" value="支付宝">支付宝</Option>
                                            <Option key="3" value="对公账户">对公账户</Option>
                                        </Select>
                                    </div>
                                    <div className="common-select staticSelect">
                                        <div className="label">消费途径：</div>
                                        <Select placeholder="请选择途径" allowClear value={cost_type || undefined} onChange={(e)=>{
                                            this.setState({
                                                query:Object.assign({},query,{cost_type:e,income_type:''})
                                            },()=>this.handleQuery())
                                        }} >
                                            <Option key="1" value="短信扣款">短信扣款</Option>
                                            <Option key="2" value="系统升级">系统升级</Option>
                                        </Select>
                                    </div>
                                    <div className="common-select staticSelect">
                                        <div className="label">金额：</div>
                                        <InputNumber value={caiwu_money_1} onChange={(e)=>{
                                            this.setState({
                                                query:Object.assign({},query,{caiwu_money_1:e})
                                            },()=>this.handleQuery())
                                        }} />-
                                        <InputNumber value={caiwu_money_2} onChange={(e)=>{
                                            this.setState({
                                                query:Object.assign({},query,{caiwu_money_2:e})
                                            },()=>this.handleQuery())
                                        }} />
                                    </div>
                                    <div className="common-select staticSelect">
                                        <div className="label">备注：</div>
                                        <Input value={remark} onChange={(e)=>{
                                            this.setState({
                                                query:Object.assign({},query,{remark:e.target.value})
                                            },()=>this.handleQuery())
                                        }} />
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <div className="box-body">
                            <Table
                                size="middle"
                                pagination={false}
                                dataSource={this.state.dataSet}
                                columns={this.parseColumn()}
                                rowKey={(e)=>{return e.id }}
                                bordered />
                        </div>
                        <div className="box-footer">
                            <PageControl current={page} pageSize={count} total={totalCount} toPage={(a)=>{
                                this.setState({query:Object.assign({},query,{page:a})},()=>{
                                    this.fetchMsgList();
                                })
                            }} />
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route
                        path={'/integrated_query/detail/:id'}
                        component={OrganizationManageDetail}
                    />
                </Switch>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
});
export default connect(mapStateToProps)(FinanceManage);
