import React, { Component } from "react";
import {
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Avatar,
} from "antd";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import qs from "qs";

const { Search, TextArea } = Input;

export class AddRecordModal extends Component {
    state = {
        end_time:'',
    };
    static propTypes = {
        onHandleCancel: PropTypes.func,
        onHandleOk: PropTypes.func,
        onSelectColumns: PropTypes.func,
        onClick: PropTypes.func,
        token: PropTypes.string,
        visible: PropTypes.bool,
        isEdit: PropTypes.bool,
        info: PropTypes.object,
    };


    handleOk = () => {
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const token = this.props.token;

        const body = {
            shop_id:this.props.shopId,
            end_time:this.state.end_time,
            user_type:"admin"
        };
        axios
            .post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_branch_add&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    message.success(res.data.msg);
                    this.props.onFetchMsgList();
                    setTimeout(() => {
                        this.props.onHandleCancel();
                    }, 100);
                }else{
                    message.error(res.data.msg)
                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    render() {
        const InputGroup = Input.Group;
        const { TextArea } = Input;
        const { Option } = Select;
        const { info, teachers, teacherList, classRooms, courses, manageData } = this.state;
        const options1 = [
            {
                key: "1",
                name: "硬笔书法",
                value: "硬笔书法",
            },
            {
                key: "2",
                name: "小学编程",
                value: "小学编程",
            },
        ];
        const formItemLayout = {
            labelCol: {
                span: 6,
            },
            wrapperCol: {
                span: 18,
            },
        };
        return (
            <Modal
                title="添加跟踪日志"
                visible={true}
                okText="确定"
                cancelText="取消"
                onOk={this.handleOk}
                onCancel={this.props.onHandleCancel}
                style={{ width: 520 }}
            >
                <Form {...formItemLayout}>
                    <Form.Item label="结束日期">
                        <DatePicker onChange={(date, dateString)=>{
                            this.setState({end_time:dateString})
                        }} disabledDate={(current) => {
                            return current && current < moment().add(0, "d");
                        }} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
});
export default connect(mapStateToProps)(AddRecordModal);
