import React, { Component, Fragment } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import store from "./store";
import { loadUser } from "./actions/auth";

import PrivateRoute from "./components/common/PrivateRoute";
import Login from "./components/accounts/Login";
import Home from "./components/home/Home";
import Create from "./components/home/Create";

// import './font/iconfont.css';
import "./index.less";
import "./modules/root/Main.less";
import "./modules/common/Breadcrumbs.less"

class App extends Component {
	componentDidMount() {
		store.dispatch(loadUser());
	}
	render() {
		return (
			<ConfigProvider locale={locale}>
				<Provider store={store}>
					<Router>
						<Fragment>
							<Switch>
								<Route exact path="/login" component={Login} />
								<PrivateRoute
									exact
									path="/dynamic/create/:id"
									component={Create}
								/>
								<PrivateRoute path="/" component={Home} />
							</Switch>
						</Fragment>
					</Router>
					{/* <Voice /> */}
				</Provider>
			</ConfigProvider>
		);
	}
}

export default App;
