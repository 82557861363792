// CREATE MESSAGE
export const toggleCollapsed = () => (dispatch, getState) => {
	const collapsed = getState().menu.menuFold;

	console.log(collapsed);

	if (collapsed) {
		dispatch({
			type: "MENU_UNFOLD",
		});
	} else {
		dispatch({
			type: "MENU_FOLD",
		});
	}
};
