import React, { Component } from "react";
import { Button, Select, Table, Modal, Form, message, Col, Row, Spin, Breadcrumb, Tabs, Icon } from "antd";
import { Link, Switch, Route } from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons';
import "./DetailRecord.less"
import { createHashHistory } from "history";
import EnterAccounModal from "./EnterAccounModal";
import AccountPaymentModal from "./AccountPaymentModal";
import axios from "axios";
import qs from "qs";
import {connect} from "react-redux";
import PageControl from "../common/PageControl";

const { confirm } = Modal;

class DetailConsume extends Component {
    constructor(props){
        super(props);
        this.state = {
            page:1,
            count:10,
            totalCount:0,
            dataSet: [],
            modal:null,
            smodal:null,
        }
    }

    componentDidMount() {
        this.fetchMsgList()
    }

    fetchMsgList = () => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token, match, shopId } = this.props;
        const body = {
            page:this.state.page,
            count:this.state.count,
            shop_id:shopId,
            sms_type:0,
            user_type:'admin'
        };
        axios
            .post("https://api.sanfeng9.com/shop/sms_admin.php?cmd=sms_all_list&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    let data = res.data.data.content
                    this.setState({
                        dataSet:data,
                        totalCount:res.data.data.allcount
                    })
                }else{

                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    deleteConfirm = (id) => {
        let _this = this;
        confirm({
            title: '删除',
            content: '确定删除此日志吗',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                _this.delete(id)
            },
            onCancel() {

            },
        });
    };
    delete = (id) => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token, shopId } = this.props
        const body = {
            shop_id:shopId,
            cmd_type:0,
            id:id,
            user_type:'admin'
        }
        axios
            .post("https://api.sanfeng9.com/shop/sms_admin.php?cmd=shop_sms_admin&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    message.success(res.data.msg);
                    this.fetchMsgList()
                }else{
                    Modal.warning({
                        title: '提示信息',
                        content: res.data.msg,
                        maskClosable:true
                    });
                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    addAccount = () => {
        const { token, match, shopId } = this.props;
        let modal = (
            <EnterAccounModal
                shopId={shopId}
                onHandleOk={() => {
                    this.setState({ visible: false });
                }}
                onHandleCancel={() => {
                    this.setState({ modal: null });
                }}
                onFetchMsgList={this.fetchMsgList}
            />
        );
        this.setState({ modal });
    };

    accountPayment = () => {
        const { token, match, shopId } = this.props;
        let smodal = (
            <AccountPaymentModal
                shopId={shopId}
                onHandleOk={() => {
                    this.setState({ visible: false });
                }}
                onHandleCancel={() => {
                    this.setState({ smodal: null });
                }}
                onFetchMsgList={this.fetchMsgList}
            />
        );
        this.setState({ smodal });
    }

    render() {
        const { match } = this.props;
        const { page, count, totalCount, dataSet } = this.state;
        const columns = [
            {
                title: "接收手机号",
                dataIndex: "to_phone",
                key: "to_phone",
                width: "200px"
            },
            {
                title: "时间",
                dataIndex: "gmt_creat",
                key: "gmt_creat",
                width: "200px"
            },
            {
                title: "内容",
                dataIndex: "sms_content",
                key: "sms_content",
            },
            {
                title: "用途",
                dataIndex: "sms_use",
                key: "sms_use"
            },
        ];
        return (
            <div className="student-signed-class">
                <Table
                    dataSource={dataSet}
                    columns={columns}
                    bordered
                    pagination={false}
                />
                <div className="box-footer">
                    <PageControl current={page} pageSize={count} total={totalCount} toPage={(a)=>{
                        this.setState({page:a},()=>{
                            this.fetchMsgList();
                        })
                    }} />
                </div>
                {this.state.modal}
                {this.state.smodal}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
});
export default connect(mapStateToProps)(DetailConsume);
