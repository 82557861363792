import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Switch, Route } from "react-router-dom";
import { Row, Col, DatePicker, Table } from "antd";
import axios from "axios";
import qs from "qs";
import SearchBar from "../../components/common/SearchBar";
import StaticSelect from "../../components/common/StaticSelect";
import PageControl from "../common/PageControl";
import OrganizationManageDetail from "./OrganizationManageDetail";
import "./OrganizationLog.less";
const { RangePicker } = DatePicker;

class OrganizationLog extends Component {
	state = {
		shop_id: "",
		keyword: "",
		ip: "",
		name: "",
		log_type: "",
		date1: "",
		date2: "",

		logTypes: [],
		filter: false,
		logData: [],
		current: 1,
		size: 10,
		totalCount: 0,
	};
	componentDidMount() {
		this.getLogData();
		this.getLogType();
	}
	getLogData = () => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const token = this.props.token;
		let { shop_id, keyword, ip, name, log_type, date1, date2, size, current } = this.state;
		let data = { shop_id, keyword, ip, name, log_type, date1, date2, count: size, page: current,user_type:"admin" };
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_log&token=" + token, qs.stringify(data), config)
			.then((res) => {
				if (res.data.response === "200") {
					this.setState({
						logData: res.data.data.content,
						current: parseInt(res.data.data.curpage),
						totalCount: res.data.data.allcount,
						size: res.data.data.pagecount,
					});
				} else {
					console.log("faile", res.data.msg);
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
	};
	getLogType = () => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const token = this.props.token;
		let data = { tb_name: "log_shop",user_type:"admin" };
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=get_log_type&token=" + token, qs.stringify(data), config)
			.then((res) => {
				// console.log(res.data);
				let logTypes = [];
				res.data.forEach((item) => {
					logTypes.push({ id: item.log_type, name: item.log_type });
				});
				this.setState({ logTypes });
			})
			.catch((err) => {
				console.log("err", err);
			});
	};
	handleReset = () => {
		this.setState(
			{
				shop_id: "",
				keyword: "",
				ip: "",
				name: "",
				log_type: "",
				data1: "",
				data2: "",
				current: 1,
				size: 10,
			},
			() => this.getLogData()
		);
	};
	handleFilter = () => {
		this.setState({ filter: !this.state.filter });
	};
	render() {
		const { shop_id, keyword, ip, log_type, filter, logData, current, size, totalCount, logTypes } = this.state;
		const { match } = this.props;
		const columns = [
			{
				title: "商户ID",
				dataIndex: "shop_id",
				key: "shop_id",
				render: (text) => (
					<Link
						style={{ color: "#008eec" }}
						to={{
							pathname: `${match.url}/detail/${text}/record`,
						}}>
						{text}
					</Link>
				),
			},
			{
				title: "商户名称",
				dataIndex: "shop_name",
				key: "shop_name",
				render: (text, record) => (
					<Link
						style={{ color: "#008eec" }}
						to={{
							pathname: `${match.url}/detail/${record.shop_id}/record`,
						}}>
						{text}
					</Link>
				),
			},
			{
				title: "操作时间",
				dataIndex: "gmt_creat",
				key: "gmt_creat",
			},
			{
				title: "IP地址",
				dataIndex: "log_ip",
				key: "log_ip",
			},

			{
				title: "操作类型",
				dataIndex: "log_type",
				key: "log_type",
			},
			{
				title: "操作人员",
				dataIndex: "name",
				key: "name",
			},
			{
				title: "操作内容",
				width: "40%",
				dataIndex: "log_content",
				key: "log_content",
			},
		];

		return (
			<div className='page organization-log'>
				<div className='page-content'>
					<div className='content-header'>
						<h1>商户日志</h1>
						<div className='box'>
							<div className='box-header'>
								<Row type='flex' justify='space-around' align='top'>
									<Col>
										<SearchBar
											searchText={shop_id}
											onSearchTextInput={(e) => {
												this.setState({ shop_id: e });
											}}
											onClick={this.getLogData}
											label='商户ID'
											placeholder='请输入商户ID'
										/>
										<SearchBar
											searchText={keyword}
											onSearchTextInput={(e) => {
												this.setState({ keyword: e });
											}}
											onClick={this.getLogData}
											label='关键字'
											placeholder='请输入操作内容关键字'
										/>
										<SearchBar
											searchText={ip}
											onSearchTextInput={(e) => {
												this.setState({ ip: e });
											}}
											onClick={this.getLogData}
											label='IP地址'
											placeholder='IP地址'
										/>
										{/* {filter && (
											<StaticSelect
												selectValue={shop_id}
												label='操作人员'
												options={[]}
												onHandleChange={(selectValue) => {
													this.setState({
														course_id: selectValue,
													});
												}}
												onSelect={this.selectColumns}
												placeholder='请选择操作人员'
											/>
										)} */}

										{filter && (
											<StaticSelect
												selectValue={log_type}
												label='操作类型'
												options={logTypes}
												onHandleChange={(selectValue) => {
													this.setState({
														log_type: selectValue,
													});
												}}
												onSelect={this.getLogData}
												placeholder='请选择操作类型'
											/>
										)}
										{filter && (
											<div className='common-select staticSelect'>
												<div className='lable'>操作日期：</div>
												<RangePicker
													style={{ flex: 1 }}
													onChange={(date, datestr) => {
														console.log(datestr);
														this.setState({ date1: datestr[0], date2: datestr[1] }, () => this.getLogData());
													}}
												/>
											</div>
										)}
									</Col>
									<Col>
										<span className='resetBtn iconfont icon' onClick={this.handleReset}>
											&#xe663;
										</span>
										{filter ? (
											<span className='filter-btn' onClick={this.handleFilter}>
												收起
												<span className='iconfont icon hidden'>&#xe60e;</span>
											</span>
										) : (
											<span className='filter-btn' onClick={this.handleFilter}>
												展开<span className='iconfont icon'>&#xe60e;</span>
											</span>
										)}
									</Col>
								</Row>
							</div>
							<div className='box-body'>
								<Table dataSource={logData} columns={columns} pagination={false} rowKey={(record) => record.id} />
							</div>
							<div className='box-footer'>
								<PageControl
									current={current}
									pageSize={size}
									total={totalCount}
									onShowSizeChange={(current, pageSize) => {
										this.setState({ current, size: pageSize });
									}}
									toPage={(e) => {
										this.setState({ current: e }, () => {
											this.getLogData();
										});
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<Switch>
					<Route path={`${match.url}/detail/:id`} component={OrganizationManageDetail} />
				</Switch>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(OrganizationLog);
