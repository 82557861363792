import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Input, Col, Row, message } from "antd";
import axios from "axios";
import qs from "qs";
import ImgPicker from "../../components/common/ImgPicker";
import "./index.less";

export function ContactInfo(props) {
	const [serviceTelData, setServiceTelData] = useState({ data: "", editFlag: false }),
		[serviceQQData, setServiceQQData] = useState({ data: "", editFlag: false }),
		[cooTelData, setCooTelData] = useState({ data: "", editFlag: false }),
		[cooQQData, setCooQQData] = useState({ data: "", editFlag: false }),
		[qrcode, setQrcode] = useState({ serviceWX: "", cooperationWX: "" });
	useEffect(() => {
		getContactData();
		// eslint-disable-next-line
	}, []);
	const getContactData = () => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token } = props;
		axios
			.post("https://api.sanfeng9.com/shop/public.php?cmd=contact_check&token=" + token, "user_type=admin", config)
			.then((res) => {
				if (res.data.response === "200") {
					let data = res.data.contact;
					let info = {};
					data.forEach((item) => {
						info = { ...info, ...item };
					});
					setServiceTelData({ ...serviceTelData, data: info.service_tel });
					setServiceQQData({ ...serviceQQData, data: info.service_qq });
					setCooTelData({ ...cooTelData, data: info.business_tel });
					setCooQQData({ ...cooQQData, data: info.business_qq });
					setQrcode({ serviceWX: info.service_wx, cooperationWX: info.business_wx });
					console.log(info);
				} else {
					console.log("fail", res.data.msg);
				}
				// console.log(res);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};
	const setContactData = (type) => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token } = props;
		let body;
		switch (type) {
			case "serviceTel":
				body = { service_tel: serviceTelData.data, user_type: "admin" };
				break;
			case "serviceQQ":
				body = { service_qq: serviceQQData.data, user_type: "admin" };
				break;
			case "cooTel":
				body = { business_tel: cooTelData.data, user_type: "admin" };
				break;
			case "cooQQ":
				body = { business_qq: cooQQData.data, user_type: "admin" };
				break;

			default:
				break;
		}
		axios
			.post("https://api.sanfeng9.com/admin/main.php?cmd=contact_set&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response === "200") {
					message.success(res.data.msg);
				} else {
					message.error(res.data.msg);
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
	};
	return (
		<div className='page'>
			<div className='page-content'>
				<div className='content-header'>
					<h1>联系方式设置</h1>
				</div>
				<div className='box'>
					<div className='card service-info'>
						<div className='card_header'>
							<i className='icon iconfont'>&#xe602;</i>
							<span>客服设置</span>
						</div>
						<div className='card_body'>
							<TextItem
								type='客服电话'
								info={serviceTelData.data}
								setInfo={(data) => {
									setServiceTelData({ ...serviceTelData, data });
								}}
								editFlag={serviceTelData.editFlag}
								setEditFlag={() => setServiceTelData({ ...serviceTelData, editFlag: !serviceTelData.editFlag })}
								saveData={() => setContactData("serviceTel")}
							/>
							<TextItem
								type='客服QQ'
								info={serviceQQData.data}
								setInfo={(data) => {
									setServiceQQData({ ...serviceQQData, data });
								}}
								editFlag={serviceQQData.editFlag}
								setEditFlag={() => setServiceQQData({ ...serviceQQData, editFlag: !serviceQQData.editFlag })}
								saveData={() => setContactData("serviceQQ")}
							/>

							<ImgItem
								type='客服微信'
								ImgType='service_wx'
								imgData={qrcode.serviceWX}
								handleImgData={(data) => {
									setQrcode({ ...qrcode, serviceWX: data });
								}}
							/>
						</div>
					</div>
					<div className='card cooperation-info'>
						<div className='card_header'>
							<i className='icon iconfont'>&#xe818;</i>
							<span>招商合作设置</span>
						</div>
						<div className='card_body'>
							<TextItem
								type='招商电话'
								info={cooTelData.data}
								setInfo={(data) => {
									setCooTelData({ ...cooTelData, data });
								}}
								editFlag={cooTelData.editFlag}
								setEditFlag={() => setCooTelData({ ...cooTelData, editFlag: !cooTelData.editFlag })}
								saveData={() => setContactData("cooTel")}
							/>
							<TextItem
								type='招商QQ'
								info={cooQQData.data}
								setInfo={(data) => {
									setCooQQData({ ...cooQQData, data });
								}}
								editFlag={cooQQData.editFlag}
								setEditFlag={() => setCooQQData({ ...cooQQData, editFlag: !cooQQData.editFlag })}
								saveData={() => setContactData("cooQQ")}
							/>
							<ImgItem
								type='招商微信'
								ImgType='business_wx'
								imgData={qrcode.cooperationWX}
								handleImgData={(data) => {
									setQrcode({ ...qrcode, cooperationWX: data });
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
const TextItem = ({ type, info, setInfo, editFlag, setEditFlag, saveData }) => (
	<div className='item'>
		<Row gutter={24}>
			<Col span={6} push={1}>
				{type + "："}
			</Col>
			<Col span={14} pull={1}>
				{editFlag ? (
					<Input
						value={info}
						style={{ width: 350 }}
						onChange={(e) => {
							setInfo(e.target.value);
						}}
					/>
				) : (
					<span>{info}</span>
				)}
			</Col>
			<Col span={4}>
				<span
					onClick={() => {
						setEditFlag(!editFlag);
					}}>
					{editFlag ? (
						<span onClick={saveData} className='icon iconfont'>
							&#xe680;
						</span>
					) : (
						<span className='icon iconfont'>&#xe60c;</span>
					)}
				</span>
			</Col>
		</Row>
	</div>
);
const ImgItem = ({ type, imgData, handleImgData, ImgType }) => (
	<div className='img_item'>
		<Row gutter={24}>
			<Col span={6} push={1}>
				{type + "："}
			</Col>
			<Col span={14} pull={1}>
				<ImgPicker
					flag={ImgType}
					imgdata={imgData}
					SetImg={(data) => handleImgData(data)}
					c_width={200}
					c_height={200}
				/>
			</Col>
		</Row>
	</div>
);
const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(ContactInfo);
