import React, { Component } from "react";
import {Row, Col, Button, Modal, Select, Table, Input, message , TreeSelect } from "antd/lib/index";
import axios from "axios/index";
import qs from "qs";
import PageControl from "../common/PageControl";
import {connect} from "react-redux";
import {Link,withRouter} from "react-router-dom";

const { Option, OptGroup } = Select;

export class Content extends Component {

    state={
        page: 1,
        count: 20,
        type_id:'',
        dataSet:[],
        treeData:[]
    };

    componentWillMount() {
        this.getTypeData();
        this.fetchContentList();
    }

    handleQuery = () => {
        this.setState({page:1},()=>{
            this.fetchContentList()
        })
    };

    fetchContentList = () => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props;
        const body = {
            page: this.state.page,
            count: this.state.count,
            keyword:this.state.keyword,
            type_id:this.state.type_id,
            user_type:"admin"
        };
        axios
            .post("https://api.sanfeng9.com/shop/public.php?cmd=help_query&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    let data = res.data.msg.content
                    this.setState({
                        dataSet: data,
                        totalCount:res.data.msg.allcount
                    });
                }else{

                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    mapTree = (org) => {
        const haveChildren = Array.isArray(org.s_type) && org.s_type.length > 0;
        if(haveChildren){
            return {
                title: org.title,
                value: org.id,
                children: org.s_type.map(i => this.mapTree(i))
            };
        }else{
            return {
                title: org.title,
                value: org.id,
            };
        }

    };

    getTypeData = () => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props;
        axios
            .post("https://api.sanfeng9.com/shop/public.php?cmd=help_get_typelist&token="+token, 'user_type=admin', config)
            .then(res => {
                if(res.data){
                    let data = res.data.map((item)=>this.mapTree(item))
                    this.setState({
                        treeData:data
                    })
                }else{

                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    deleteConfirm = (id) => {
        let _this = this;
        Modal.confirm({
            title: '删除',
            content: '确定删除此帮助吗',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                _this.delete(id)
            },
            onCancel() {

            },
        });
    };
    delete = (id) => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props
        const body = {
            cmd_type:'del',
            id:id,
            user_type:"admin"
        }
        axios
            .post("https://api.sanfeng9.com/admin/help.php?cmd=content_edit&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    message.success(res.data.msg);
                    this.fetchContentList()
                }else{
                    Modal.warning({
                        title: '提示信息',
                        content: res.data.msg,
                        maskClosable:true
                    });
                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    parseColumn = () => {
        const { match } = this.props;
        return [
            {
                title: "帮助内容标题",
                dataIndex: "title",
                render: (text, record) => (
                    <a href={`https://www.sanfeng9.com/help/?id=${record.type_id}&aid=${record.id}`} target="_blank" style={{ color: "#ff7519" }}>{text}</a>
                ),
            },
            {
                title: "发布者",
                dataIndex: "author",
                width: "100px",
            },
            {
                title: "添加时间",
                dataIndex: "setdate",
            },
            {
                title: "排序",
                dataIndex: "paixu",
                width: "100px",
            },
            {
                title: "显示",
                dataIndex: "display",
                width: "100px",
            },
            {
                title: "浏览次数",
                dataIndex: "visit",
                width: "100px",
            },
            {
                title: "有帮助",
                dataIndex: "help_ok",
                width: "100px",
            },
            {
                title: "无帮助",
                dataIndex: "help_no",
                width: "100px",
            },
            {
                title: "操作",
                dataIndex: "operate",
                width: "150px",
                render: (text, record, index) => {
                    return [
                        <a onClick={()=>{
                            this.props.handleEdit();
                            this.props.history.push({pathname:"/help/add",query: record})
                        }} style={{marginRight:10}}>修改</a>,
                        <a onClick={this.deleteConfirm.bind(this,record.id)} >删除</a>
                    ]
                },
            },
        ];
    }

    render() {
        const { match } = this.props;
        const { keyword, type_id, page, count, totalCount, treeData } = this.state;
        return (
            <div className="help-content">
                <div className="box-header">
                    <Row align="top" gutter={16}>
                        <Col span={6}>
                            <div className="common-select staticSelect">
                                <div className="label">标题：</div>
                                <Input value={keyword} onChange={(e)=>{
                                    this.setState({
                                        keyword:e.target.value
                                    },()=>this.handleQuery())
                                }} />
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="common-select staticSelect">
                                <div className="label">分类：</div>
                                <TreeSelect
                                    allowClear
                                    style={{ width: '100%' }}
                                    value={this.state.type_id || undefined}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={treeData}
                                    placeholder="请选择类型"
                                    treeDefaultExpandAll
                                    onChange={(value)=>{
                                        this.setState({
                                            type_id:value
                                        },()=>{
                                            this.handleQuery()
                                        })
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="box-body">
                    <Table size="middle" pagination={false} dataSource={this.state.dataSet} columns={this.parseColumn()} rowKey={(e)=>{return e.id }} bordered />
                </div>
                <div className="box-footer">
                    <PageControl current={page} pageSize={count} total={totalCount} toPage={(a)=>{
                        this.setState({page:a},()=>{
                            this.fetchContentList()
                        })
                    }} />
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    token: state.auth.token,
});
export default connect(
    mapStateToProps
)(withRouter(Content));
