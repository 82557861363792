import React, { Component } from "react";
import {Row, Col, Button, Modal, Select, Table, Input, InputNumber, Radio, message, TreeSelect} from "antd/lib/index";
import axios from "axios/index";
import qs from "qs";
import PageControl from "../common/PageControl";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import E from "wangeditor";
import './Add.less'

const { Option } = Select;

export class Add extends Component {

    state = {
        id:'',
        title:'',
        paixu:'',
        display:1,
        type_id:'',
        content: "",
        editor: "",
        treeData:[]
    };

    componentDidMount() {
        const token = this.props.token;
        const elem = this.refs.editorElem;
        const editor = new E(elem);

        // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
        editor.customConfig.onchange = (html) => {
            this.setState({
                content: html,
            });

        };
        editor.customConfig.menus = [
            'head',  // 标题
            'bold',  // 粗体
            'fontSize',  // 字号
            'fontName',  // 字体
            'italic',  // 斜体
            'underline',  // 下划线
            'strikeThrough',  // 删除线
            'foreColor',  // 文字颜色
            'backColor',  // 背景颜色
            'link',  // 插入链接
            'list',  // 列表
            'justify',  // 对齐方式
            'quote',  // 引用
            'emoticon',  // 表情
            'image',  // 插入图片
            'table',  // 表格
            'video',  // 插入视频
            'code',  // 插入代码
            'undo',  // 撤销
            'redo'  // 重复
        ];
        editor.customConfig.customUploadImg = function (files, insert) {
            // Headers
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };
            const formData = new FormData();
            // // 添加要上传的文件
            formData.append('file', files[0]);
            formData.append('user_type', "admin");
            axios
                .post("https://api.sanfeng9.com/shop/main.php?cmd=img_upload&token="+token, formData, config)
                .then(res => {
                    if(res.data.response == 200){
                        insert(res.data.img_url)
                    }else{
                        message.error('')
                    }
                })
                .catch(err => {
                    console.log(err)
                });
        };

        editor.create();
        this.getTypeData();
        if (this.props.location.query) {
            const { query } = this.props.location
            this.setState(
                {
                    id: query.id,
                    title: query.title,
                    paixu: query.paixu,
                    display: Number(query.display),
                    type_id: query.type_id,
                    content: query.content,
                },()=>{
                    editor.txt.html(this.state.content);
                }
            )
        }
    }

    mapTree = (org) => {
        const haveChildren = Array.isArray(org.s_type) && org.s_type.length > 0;
        if(haveChildren){
            return {
                title: org.title,
                value: org.id,
                children: org.s_type.map(i => this.mapTree(i))
            };
        }else{
            return {
                title: org.title,
                value: org.id,
            };
        }

    };

    getTypeData = () => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props;
        axios
            .post("https://api.sanfeng9.com/shop/public.php?cmd=help_get_typelist&token="+token, 'user_type=admin', config)
            .then(res => {
                if(res.data){
                    let data = res.data.map((item)=>this.mapTree(item))
                    this.setState({
                        treeData:data
                    })
                }else{

                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    add = (id) => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props;
        let body;
        if(this.state.id){
            body = {
                cmd_type:'edit',
                id:this.state.id,
                title:this.state.title,
                paixu:this.state.paixu,
                display:this.state.display,
                content:this.state.content,
                type_id:this.state.type_id,
                user_type:"admin"
            }
        }else{
            body = {
                cmd_type:'add',
                title:this.state.title,
                paixu:this.state.paixu,
                display:this.state.display,
                content:this.state.content,
                type_id:this.state.type_id,
                user_type:"admin"
            }
        }
        axios
            .post("https://api.sanfeng9.com/admin/help.php?cmd=content_edit&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    message.success(res.data.msg);
                    this.props.handleEdit();
                    this.props.history.push({pathname:"/help"})
                }else{
                    Modal.warning({
                        title: '提示信息',
                        content: res.data.msg,
                        maskClosable:true
                    });
                }
            })
            .catch(err => {
                console.log(err)
            });
    };


    render() {
        const { treeData } = this.state;
        return (
            <div className="help-add">
                <div className="box-header">
                    <Row align="top" gutter={16}>
                        <Col span={6}>
                            <div className="common-select staticSelect">
                                <div className="label">标题：</div>
                                <Input value={this.state.title} onChange={(e)=>{
                                    this.setState({
                                        title:e.target.value
                                    })
                                }} />
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="common-select staticSelect">
                                <div className="label">排序：</div>
                                <InputNumber value={this.state.paixu} onChange={(value)=>{
                                    this.setState({
                                        paixu:value
                                    })
                                }} />
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="common-select staticSelect">
                                <div className="label">类型：</div>
                                <TreeSelect
                                    allowClear
                                    style={{ width: '100%' }}
                                    value={this.state.type_id || undefined}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={treeData}
                                    placeholder="请选择类型"
                                    treeDefaultExpandAll
                                    onChange={(value)=>{
                                        this.setState({
                                            type_id:value
                                        })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col span={4}>
                            <div>
                                <Radio.Group onChange={(e)=>{
                                    this.setState({
                                        display: e.target.value,
                                    });
                                }} value={this.state.display}>
                                    <Radio value={1}>显示</Radio>
                                    <Radio value={0}>隐藏</Radio>
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div
                    ref="editorElem"
                ></div>
                <div style={{textAlign:"center",marginTop:20}}>
                    <Button type="primary" onClick={this.add}>保存</Button>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    token: state.auth.token,
});
export default connect(
    mapStateToProps
)(withRouter(Add));
