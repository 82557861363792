import React, { Component } from "react";
import { Col, DatePicker, Form, Input, InputNumber, message, Modal, Radio, Row, Select, Avatar } from "antd";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import qs from "qs";

const { Search, TextArea } = Input;

export class AccountPaymentModal extends Component {
	state = {
		money: "",
		use: "",
		reason: "",
		salesman: "",
		remark: "",
	};
	static propTypes = {
		onHandleCancel: PropTypes.func,
		onHandleOk: PropTypes.func,
		onSelectColumns: PropTypes.func,
		onClick: PropTypes.func,
		token: PropTypes.string,
		visible: PropTypes.bool,
		isEdit: PropTypes.bool,
		info: PropTypes.object,
	};

	handleOk = () => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const token = this.props.token;

		const body = {
			shop_id: this.props.shopId,
			caiwu_type: 0,
			caiwu_money: this.state.money,
			cost_type: this.state.use,
			remark: this.state.remark,
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_caiwu_add&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					message.success(res.data.msg);
					this.props.onFetchMsgList();
					setTimeout(() => {
						this.props.onHandleCancel();
					}, 100);
				} else {
					message.error(res.data.msg);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render() {
		const InputGroup = Input.Group;
		const { TextArea } = Input;
		const { Option } = Select;
		const { money, data, source, use, reason, remark } = this.state;
		const options1 = [
			{
				key: "1",
				name: "硬笔书法",
				value: "硬笔书法",
			},
			{
				key: "2",
				name: "小学编程",
				value: "小学编程",
			},
		];
		const formItemLayout = {
			labelCol: {
				span: 6,
			},
			wrapperCol: {
				span: 18,
			},
		};
		return (
			<Modal
				title='财务扣款'
				visible={true}
				okText='确定'
				cancelText='取消'
				onOk={this.handleOk}
				onCancel={this.props.onHandleCancel}
				style={{ width: 520 }}>
				<Form {...formItemLayout}>
					<Form.Item label='金额'>
						<Input
							value={money}
							onChange={(e) => {
								this.setState({
									money: e.target.value,
								});
							}}
							style={{ width: 300 }}
						/>
					</Form.Item>
					<Form.Item label='消费用途'>
						<Select
							placeholder='请选择消费用途'
							value={use || undefined}
							onChange={(e) => {
								this.setState({
									use: e,
								});
							}}
							style={{ width: 300 }}>
							<Option key='1' value='系统续费'>
								系统续费
							</Option>
							<Option key='2' value='系统升级'>
								系统升级
							</Option>
						</Select>
					</Form.Item>

					<Form.Item label='原因'>
						<TextArea
							value={remark}
							onChange={(e) => {
								this.setState({
									remark: e.target.value,
								});
							}}
							rows={4}
							style={{ width: 300 }}
						/>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(AccountPaymentModal);
