import React, { Component } from "react";
import { connect } from "react-redux";

import "../../modules/center/Home.less";
// import Echart from "../echarts_module/Echart";
import { message } from "antd";
import axios from "axios";
import copy from "copy-to-clipboard";

export class Index extends Component {
  state = {
    showDataList: [],
    showMyDataList: [],
    userInfo: {},
  };
  componentDidMount() {
    /*this.getTableData();*/
    this.fetchRecordList();
    this.getCurrentUser();
  }

  fetchRecordList = () => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const { token } = this.props;
    axios
      .post(
        "https://api.sanfeng9.com/admin/main.php?cmd=main_report&token=" +
          token,
        "user_type=admin",
        config
      )
      .then((res) => {
        if (res.data.response == 200) {
          this.setState({
            showDataList: [
              {
                title: "永久免费版",
                key: "10",
                count: res.data.shop_10,
              },
              {
                title: "单门店版",
                key: "100",
                count: res.data.shop_100,
              },
              {
                title: "五门店版",
                key: "500",
                count: res.data.shop_500,
              },
              {
                title: "不限门店版",
                key: "999",
                count: res.data.shop_999,
              },
            ],
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentUser = () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const token = this.props.token;
    axios
      .post(
        "https://api.sanfeng9.com/admin/admin.php?cmd=cur_userinfo&token=" +
          token,
        "user_type=admin",
        config
      )
      .then((res) => {
        if (res.data.response === "200") {
          console.log(res);
          let data = res.data.msg;
          this.setState({
            userInfo: data,
            sale_qrcode: data.sale_qrcode,
            sale_url: data.sale_url,
            showMyDataList: [
              {
                title: "全部商户数量",
                type: "",
                count:
                  Number(data.num_100) +
                  Number(data.num_201) +
                  Number(data.num_202),
              },
              {
                title: "试用版商户数量",
                type: "100",
                count: data.num_100,
              },
              {
                title: "标准版商户数量",
                type: "201",
                count: data.num_201,
              },
              {
                title: "旗舰版商户数量",
                type: "202",
                count: data.num_202,
              },
            ],
          });
        } else {
          console.log(res.data.msg);
        }
      });
  };

  getTableData = () => {
    const date1 = this.getDiffDate("2020-01-19", "2020-04-28");
    const date2 = this.getDiffDate("2020-03-31", "2020-04-28");
    const values1 = this.getRandomArr(date1.length, 50, 450);
    const values2 = this.getRandomArr(date2.length, 0, 150000);
    const xAxis1 = {
      boundaryGap: false,
      data: date1,
    };
    const xAxis2 = {
      boundaryGap: false,
      data: date2,
    };
    const series1 = [
      {
        name: "注册量",
        type: "line",
        data: values1,
        areaStyle: {},
        itemStyle: {
          color: "#5C9ED6",
        },
      },
    ];
    const series2 = [
      {
        name: "毛收入",
        type: "line",
        data: values2,
        areaStyle: {},
        itemStyle: {
          color: "#9CA2A8",
        },
      },
    ];
    this.setState({
      xAxis1,
      xAxis2,
      series1,
      series2,
    });
  };
  getDiffDate = (start, end) => {
    var startTime = this.getDate(start);

    var endTime = this.getDate(end);

    var dateArr = [];

    while (endTime.getTime() - startTime.getTime() > 0) {
      var year = startTime.getFullYear();

      var month =
        startTime.getMonth().toString().length === 1
          ? "0" + (parseInt(startTime.getMonth().toString(), 10) + 1)
          : startTime.getMonth() + 1;

      var day =
        startTime.getDate().toString().length === 1
          ? "0" + startTime.getDate()
          : startTime.getDate();

      dateArr.push(year + "-" + month + "-" + day);

      startTime.setDate(startTime.getDate() + 1);
    }

    return dateArr;
  };
  getDate = (datestr) => {
    var temp = datestr.split("-");

    if (temp[1] === "01") {
      temp[0] = parseInt(temp[0], 10) - 1;

      temp[1] = "12";
    } else {
      temp[1] = parseInt(temp[1], 10) - 1;
    }

    //new Date()的月份入参实际都是当前值-1

    var date = new Date(temp[0], temp[1], temp[2]);

    return date;
  };
  getRandomArr = (n, min, max) => {
    var arr = [];
    for (var i = 0; i < n; i++) {
      var random = Math.floor(Math.random() * (max - min + 1) + min);
      arr.push(random);
    }
    return arr;
  };
  goToOrgManage = (data) => {
    console.log(data);
    this.props.history.push({
      pathname: "/integrated_query",
      state: { type: data.key, manager: this.state.userInfo.id },
    });
  };

  render() {
    const { showDataList, showMyDataList, userInfo } = this.state;
    const { wxList } = this.props;
    const title1 = {
      text: "注册走势",
      x: "left",
      textStyle: {
        fontWeight: "normal",
      },
      padding: 15,
    };
    const title2 = {
      text: "毛收入走势",
      x: "left",
      textStyle: {
        fontWeight: "normal",
      },
      padding: 15,
    };
    return (
      <div className="container-wrap home">
        {userInfo && userInfo.groupname === "管理员" ? (
          <div className="box-container home-box">
            <div className="box-header">
              <span className="box-title">商户数据</span>
              <span className="icon iconfont open">&#xe633;</span>
            </div>
            <div className="box-body without-padding">
              <ul className="top-line-box">
                {showDataList.map((item, index) => (
                  <li key={index} className="top-line-student">
                    <div className="line-border">
                      <div onClick={() => this.goToOrgManage(item)}>
                        <div className="number-tip">
                          <span className="right-text">
                            {item.count}
                            <span className="simple-icon">个</span>
                          </span>
                        </div>
                        <div className="small-title-tip">
                          <span>{item.title}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}

        <div className="box-container home-box">
          <div className="box-header">
            <span className="box-title">客服列表</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              // backgroundColor: "pink",
              paddingLeft: "20px",
              paddingRight: "10px",
              marginTop: "10px",
            }}
          >
            {wxList.map((item, index) => {
              if (item.groupname === "客服") {
                return (
                  <div
                    key={item.id}
                    style={{
                      cursor: "default",
                      backgroundColor:
                        item.online === "1" ? "#54B536" : "#E0E0E0",
                      width: "100px",
                      height: "26px",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: "20px",
                      justifyContent: "space-between",
                      padding: "0 10px",
                    }}
                  >
                    <span
                      class="icon iconfont"
                      style={{ color: "#fff", fontSize: "24px" }}
                    >
                      &#xe61a;
                    </span>
                    <span
                      style={{
                        color: item.online === "1" ? "#fff" : "#ABABAB",
                      }}
                    >
                      {" "}
                      {item.realname}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user_info: state.auth.user,
  wxList: state.wechat.wxList,
});
export default connect(mapStateToProps)(Index);
