import axios from "axios";
import { Modal } from "antd";

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: "USER_LOADING" });
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  if (token) {
    axios
      .post(
        "https://api.sanfeng9.com/admin/admin.php?cmd=cur_userinfo&token=" +
          token,
        "user_type=admin",
        config
      )
      .then((res) => {
        if (res.data.response === "200") {
          dispatch({
            type: "USER_LOADED",
            payload: res.data.msg,
          });
        } else {
          dispatch({
            type: "AUTH_ERROR",
          });
        }
      });
  } else {
    dispatch({ type: "USER_UNTOKEN" });
  }
};
export const login = (username, passwd) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const body = "phone=" + username + "&password=" + passwd + "&user_type=admin";
  axios
    .post("https://api.sanfeng9.com/admin/login.php?cmd=login", body, config)
    .then((res) => {
      if (res.data.response === "200") {
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: res.data,
        });
      } else {
        Modal.warning({
          title: "提示信息",
          content: res.data.msg,
          maskClosable: true,
        });
        dispatch({
          type: "LOGIN_FAIL",
        });
      }
    });
};
export const logout = () => (dispatch, getState) => {
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  axios
    .post(
      "https://api.sanfeng9.com/admin/login.php?cmd=logout&token=" + token,
      "user_type=admin",
      config
    )
    .then((res) => {
      if (res.data.response === "200") {
        dispatch({
          type: "LOGOUT_SUCCESS",
        });
      } else {
        Modal.warning({
          title: "提示信息",
          content: res.data.msg,
          maskClosable: true,
        });
      }
    })
    .catch((err) => {
      Modal.warning({
        title: "提示信息",
        content: err,
        maskClosable: true,
      });
    });
};
export const resetUserInfo = () => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const token = getState().auth.token;
  axios
    .post(
      "https://api.sanfeng9.com/admin/admin.php?cmd=cur_userinfo&token=" +
        token,
      "user_type=admin",
      config
    )
    .then((res) => {
      if (res.data.response === "200") {
        dispatch({
          type: "RESET_USERINFO",
          payload: res.data.msg,
        });
      } else {
        console.log(res.data.msg);
      }
    });
};

// // Setup config with token - helper function
// export const tokenConfig = getState => {
//     // Get token from state
//     const token = getState().auth.token;

//     // Headers
//     const config = {
//         headers: {
//             "Content-Type": "application/json"
//         }
//     };

//     // If token, add to headers config
//     if (token) {
//         config.headers["token"] = `${token}`;
//     }

//     return config;
// };
