import React, { Component } from "react";
import { connect } from "react-redux";
import { resetUserInfo } from "../../../actions/auth.js";
import axios from "axios";

import { Button, Table, Tag, Radio, message, Modal, Tabs } from "antd";

import AccountInfoEditModal from "./AccountInfoEditModal.js";
import confirm from "antd/lib/modal/confirm";
import PermissionSet from "./PermissionSet";

import "../../../modules/system_setting/ManagerSetPage.less";
import PageControl from "../../../modules/common/PageControl";
import qs from "qs";

export class ManagerList extends Component {
  state = {
    sourceData: [],
    selectedData: undefined,
    selectedGroup: "all",
    modal: null,
    current: 1,
    size: 10,
    totalCount: 0,
  };

  fetchMsgList = () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const token = this.props.token;
    const body = {
      // page: this.state.current,
      // count: 10,
      user_type: "admin",
    };
    axios
      .post(
        "https://api.sanfeng9.com/admin/admin.php?cmd=admin_list&token=" +
          token,
        qs.stringify(body),
        config
      )
      .then((res) => {
        if (res.data.response === "200") {
          let manageList = [],
            accountantList = [],
            salesmanList = [],
            customerList = [],
            operationengineerList = [];
          res.data.data.content.forEach((item) => {
            switch (item.groupname) {
              case "管理员":
                manageList.push(item);
                break;
              case "财务":
                accountantList.push(item);
                break;
              case "销售":
                salesmanList.push(item);
                break;
              case "客服":
                customerList.push(item);
                break;
              case "运维":
                operationengineerList.push(item);
                break;
              default:
                break;
            }
          });
          let data = {
            allList: res.data.data.content,
            manageList,
            accountantList,
            salesmanList,
            customerList,
            operationengineerList,
          };
          console.log(data);
          this.setState({
            sourceData: data,
            selectedData: res.data.data.content,
          });
        } else {
          console.log("fail", res.data.msg);
        }
      });
  };
  selectGroup = (e) => {
    const group = e.target.value;
    const {
      allList,
      manageList,
      accountantList,
      salesmanList,
      customerList,
      operationengineerList,
    } = this.state.sourceData;

    switch (group) {
      case "all":
        this.setState({
          selectedGroup: group,
          selectedData: allList,
        });
        break;
      case "manager":
        this.setState({
          selectedGroup: group,
          selectedData: manageList,
        });
        break;
      case "accountant":
        this.setState({
          selectedGroup: group,
          selectedData: accountantList,
        });
        break;
      case "salesman":
        this.setState({
          selectedGroup: group,
          selectedData: salesmanList,
        });
        break;
      case "customer":
        this.setState({
          selectedGroup: group,
          selectedData: customerList,
        });
        break;
      case "operationengineer":
        this.setState({
          selectedGroup: group,
          selectedData: operationengineerList,
        });
        break;
      default:
        return;
    }
  };
  handleAddAccount = () => {
    let modal = (
      <AccountInfoEditModal
        isEdit={false}
        info={{}}
        onHandleOk={() => {
          this.setState({ visible: false });
        }}
        onHandleCancel={() => {
          this.setState({ modal: null });
          this.props.resetUserInfo();
        }}
        onSelectColumns={this.fetchMsgList}
      />
    );
    this.setState({ modal });
  };
  handleEditAccount = (record) => {
    let modal = (
      <AccountInfoEditModal
        isEdit={true}
        info={record}
        onHandleOk={() => {
          this.setState({ visible: false });
        }}
        onHandleCancel={() => {
          this.setState({ modal: null });
          this.props.resetUserInfo();
        }}
        onSelectColumns={this.fetchMsgList}
      />
    );

    this.setState({ modal });
  };
  handleDeleteAccount = (id) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const token = this.props.token;
    const body = "id=" + id + "&user_type=admin";
    axios
      .post(
        "https://api.sanfeng9.com/admin/admin.php?cmd=admin_del&token=" + token,
        body,
        config
      )
      .then((res) => {
        if (res.data.response === "200") {
          message.success(res.data.msg);
          this.fetchMsgList();
        } else {
          Modal.warning({
            title: "提示信息",
            content: res.data.msg,
            maskClosable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  confirmDeleteAccount = (record) => {
    let _this = this;
    confirm({
      title: record.name ? "警告：删除账号 " + record.name : "警告：删除账号",
      content: "数据删除后不能恢复，您是否确认删除！",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        _this.handleDeleteAccount(record.id);
      },
      onCancel() {},
    });
  };
  componentDidMount() {
    this.fetchMsgList();
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { sourceData, selectedData, selectedGroup, modal, totalCount } =
      this.state;
    const {
      allList,
      manageList,
      accountantList,
      salesmanList,
      customerList,
      operationengineerList,
    } = sourceData;

    const columns = [
      {
        title: "账号ID",
        dataIndex: "id",
        key: "id",
        render: (id) => <span style={{ color: "#3A8FE5" }}>{id}</span>,
      },
      {
        title: "姓名",
        dataIndex: "realname",
        key: "realname",
      },
      {
        title: "活动时间",
        dataIndex: "last_time",
        key: "last_time",
        render: (activeTime) => (
          <div className="statues">
            {activeTime ? (
              <>
                <span
                  className="icon iconfont"
                  style={{
                    color: "#5DBC38",
                    fontSize: "22px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                >
                  &#xe619;
                </span>
                <span
                  style={{
                    color: "#9D2515",
                  }}
                >
                  {activeTime + "前"}
                </span>
              </>
            ) : (
              <>
                <span
                  className="icon iconfont"
                  style={{
                    fontSize: "22px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                    color: "#ccc",
                  }}
                >
                  &#xe616;
                </span>
                <span
                  style={{
                    color: "#ccc",
                  }}
                ></span>
              </>
            )}
          </div>
        ),
      },
      {
        title: "最后活动IP",
        dataIndex: "last_ip",
        key: "last_ip",
        render: (ip, record) => (
          <div>
            {record.last_time ? (
              <>
                <span>{ip + "  [" + record.ip_addr + "]"}</span>
              </>
            ) : (
              <>
                <span>尚未登录</span>
              </>
            )}
          </div>
        ),
      },
      {
        title: "分组",
        dataIndex: "groupname",
        key: "groupname",
        render: (group) => {
          switch (group) {
            case "管理员":
              return (
                <Tag color="#6638F0" key={group}>
                  {group}
                </Tag>
              );
            case "财务":
              return (
                <Tag color="#F78AE0" key={group}>
                  {group}
                </Tag>
              );
            case "销售":
              return (
                <Tag color="#5CC9F5" key={group}>
                  {group}
                </Tag>
              );
            case "客服":
              return (
                <Tag color="#FE9908" key={group}>
                  {group}
                </Tag>
              );
            case "运维":
              return (
                <Tag color="#2CDC71" key={group}>
                  {group}
                </Tag>
              );
            default:
              return;
          }
        },
      },
      {
        title: "手机号码",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "QQ",
        dataIndex: "qq",
        key: "qq",
        render: (qq) => <span>{qq ? qq : "--"}</span>,
      },
      {
        title: "备注",
        dataIndex: "remark",
        key: "remark",
        render: (remark) => {
          if (remark) {
            if (remark.length <= 10) {
              return <span>{remark}</span>;
            } else {
              return <span>{remark.substring(0, 10) + "..."}</span>;
            }
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        title: "操作",
        dataIndex: "handle",
        key: "handle",
        align: "right",
        render: (text, record) => (
          <div className="handle">
            <span
              className="icon fr iconfont edit"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleEditAccount(record)}
            >
              &#xe60c;
            </span>
            <span
              className="icon fr iconfont"
              style={{ marginRight: "15px", cursor: "pointer" }}
              onClick={() => this.confirmDeleteAccount(record)}
            >
              &#xe601;
            </span>
          </div>
        ),
      },
    ];
    return (
      <div className="template-setting-index page academic-set-page">
        <div className="content-header">
          <h1>账号管理</h1>
        </div>
        <div className="box">
          <div className="setting-container">
            <div className="setting-header">
              <Button onClick={this.handleAddAccount} type="primary">
                添加账号
              </Button>
              <>
                <Radio.Group onChange={this.selectGroup} value={selectedGroup}>
                  <Radio.Button value="all">
                    {allList ? "全部（" + allList.length + "）" : "全部（0）"}
                  </Radio.Button>
                  <Radio.Button value="manager">
                    {manageList
                      ? "管理员（" + manageList.length + "）"
                      : "管理员（0）"}
                  </Radio.Button>
                  <Radio.Button value="accountant">
                    {accountantList
                      ? "财务（" + accountantList.length + "）"
                      : "财务（0）"}
                  </Radio.Button>
                  <Radio.Button value="salesman">
                    {salesmanList
                      ? "销售（" + salesmanList.length + "）"
                      : "销售（0）"}
                  </Radio.Button>
                  <Radio.Button value="customer">
                    {customerList
                      ? "客服（" + customerList.length + "）"
                      : "客服（0）"}
                  </Radio.Button>
                  <Radio.Button value="operationengineer">
                    {operationengineerList
                      ? "运维（" + operationengineerList.length + "）"
                      : "运维（0）"}
                  </Radio.Button>
                </Radio.Group>
              </>
            </div>
            <div className="setting-body">
              <Table
                columns={columns}
                dataSource={selectedData || sourceData}
                pagination={{
                  total: selectedData ? selectedData.length : totalCount,
                  showTotal: (total) => `共 ${total} 条`,
                }}
                rowKey={(e) => e.id}
                bordered
                fixed={true}
                onChange={this.handleFileter}
              />
            </div>
            {modal}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});
export default connect(mapStateToProps, { resetUserInfo })(ManagerList);
