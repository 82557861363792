import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";

import TemplateList from "../page/WxCreate/TemplateList";
import TemplateEdit from "../page/WxCreate/TemplateEdit";
import "../../modules/show/ShowPage.less";

const { TabPane } = Tabs;

export class WxCreate extends Component {
    state = {
        showType: "lessonType",
        edit: false,
        currentId: "",
    };
    handleEdit = (id) => {
        this.setState({
            edit: !this.state.edit,
            currentId: id,
        });
    };
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        return (
            <div className="template-show-list page">
                <section className="content-header">
                    <h1>微信通知制作</h1>
                </section>
                <div className="box">
                    <div className="box-header">

                    </div>
                    {this.state.edit ? (
                        <TemplateEdit
                            handleEdit={this.handleEdit}
                            currentId={this.state.currentId}
                            type={this.state.showType}
                        />
                    ) : (
                        <TemplateList
                            type={this.state.showType}
                            handleEdit={this.handleEdit}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
});
export default connect(mapStateToProps)(WxCreate);
