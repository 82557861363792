import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";
import { Button, Select, Table, Modal, Input, message, Col, Row, Tooltip } from "antd";
import axios from "axios";
import $ from "jquery";
import qs from "qs";
import OrganizationManageDetail from "./OrganizationManageDetail";
import "../common/StaticSelect.less";
import { connect } from "react-redux";
import { UserInfo } from "../../components/home/UserInfo";
import PageControl from "../common/PageControl";
import SearchBar from "../../components/common/SearchBar";
import moment from "moment";
const { Option } = Select;
const { confirm } = Modal;

class OrganizationManage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataSet: [],
			query: {
				current: 1,
				size: 10,
				type: "",
				beginTime: "",
				loginTime: "",
				endTime: "",
				manager: "",
				commision: "",
				progress: "",
				user_type: "admin",
			},
			totalCount: 0,
			manageData: [],
		};
	}

	componentDidMount() {
		console.log(this.props.location.state);
		if (this.props.location.state) {
			this.setState(
				{
					name: this.props.location.state.name,
					query: {
						...this.state.query,
						...{ type: this.props.location.state.type, manager: this.props.location.state.manager },
					},
				},
				() => {
					this.fetchMsgList();
					this.getManageData();
				}
			);
		} else {
			this.fetchMsgList();
			this.getManageData();
		}
	}
	componentWillUnmount() {
		this.setState = () => false;
	}

	componentWillMount() {
		$(window.document).bind("entity", (e, t) => {
			this.setState(
				{
					name: t,
				},
				() => {
					this.fetchMsgList();
					this.getManageData();
				}
			);
		});
	}

	handleQuery = () => {
		this.setState(
			{
				query: Object.assign({}, this.state.query, { current: 1 }),
			},
			() => this.fetchMsgList()
		);
	};

	fetchMsgList = () => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token } = this.props;
		const { name, query } = this.state;
		const { current, size, type, beginTime, loginTime, endTime, manager, commision, progress } = query;

		const body = {
			name_phone: name,
			page: current,
			count: size,
			shop_level: type,
			gmt_creat: beginTime,
			laston_time: loginTime,
			end_time: endTime,
			sale_user_id: manager,
			ticheng: commision,
			sale_level: progress,
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_list&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					console.log(res.data.data.content);
					let data = res.data.data.content.map((item, index) => {
						return {
							id: item.id,
							targetName: item.name,
							phone: item.phone,
							type: item.shop_level,
							startTime: item.gmt_creat,
							loginTime: item.laston_time,
							endTime: item.end_time,
							manage: item.sale_user_id || "",
							commision: item.ticheng,
							saleLevel: item.sale_level === "待沟通" ? 0 : 1,
							isLock: item.is_lock,
							saleLog: item.sale_log,
							regIp: item.reg_ip,
							regAddr: item.reg_ip_addr,
						};
					});
					this.setState({
						dataSet: data,
						totalCount: res.data.data.allcount,
					});
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	getManageData = () => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const token = this.props.token;
		axios
			.post("https://api.sanfeng9.com/admin/admin.php?cmd=admin_list&token=" + token, "user_type=admin", config)
			.then((res) => {
				if (res.data) {
					const data = res.data.data.content.map((item) => ({
						key: item.id,
						id: item.id,
						name: item.realname,
						activeTime: item.last_time,
						ip: item.last_ip,
						ipAddr: item.ip_addr,
						group: item.groupname,
						phone: item.phone,
						qq: item.qq,
						superAdmin: item.super_admin,
					}));
					// .filter((item) => {
					// 	return item.group === "销售";
					// });
					this.setState(
						{
							manageData: data,
						},
						() => {
							this.fetchMsgList();
						}
					);
				}
			});
	};

	parseColumn = () => {
		const { match } = this.props;
		// const TimeShow = ({ time }) => {
		// 	let Item = time ? (
		// 		<Tooltip placement='top' title={time}>
		// 			<span>{moment(time).format("YYYY-MM-DD")}</span>
		// 		</Tooltip>
		// 	) : (
		// 		<span>--</span>
		// 	);
		// 	return Item;
		// };

		return [
			{
				title: "ID",
				width: "6%",
				dataIndex: "id",
				key: "id",
				render: (text, record) => (
					<span>
						<Link
							style={{ color: "#ff7519" }}
							to={{
								pathname: `${match.url}/detail/${record.id}/branch`,
								query: { record },
							}}>
							{text}
						</Link>
						<span onClick={this.deleteConfirm.bind(this, record.id)} className='icon delete fr iconfont'></span>
					</span>
				),
			},
			{
				title: "商户名称",
				width: "14%",
				dataIndex: "targetName",
				key: "targetName",
				render: (text, record) => (
					<Link
						style={{ color: "#ff7519" }}
						to={{
							pathname: `${match.url}/detail/${record.id}/record`,
							query: { record },
						}}>
						{text}
					</Link>
				),
			},
			{
				title: "类型",
				width: "6%",
				dataIndex: "type",
				key: "type",
				align: "center",
				render: (text, record) => {
					let tag;
					if (text == "100") {
						tag = (
							<span
								style={{
									color: "#008eec",
									borderColor: "#008eec",
								}}>
								单门店版
							</span>
						);
					} else if (text == "10") {
						tag = (
							<span
								style={{
									color: "rgb(191, 191, 191)",
									borderColor: "rgb(191, 191, 191)",
								}}>
								永久免费版
							</span>
						);
					} else if (text == "500") {
						tag = (
							<span
								style={{
									color: "red",
									borderColor: "rgb(59, 189, 170)",
								}}>
								五门店版
							</span>
						);
					} else {
						tag = (
							<span
								style={{
									color: "#ff7519",
									borderColor: "#ff7519",
								}}>
								不限门店版
							</span>
						);
					}
					return (
						<>
							{tag}
							{record.isLock === "0" ? (
								<span
									className='icon iconfont'
									style={{ color: "#ff7519", marginLeft: 8, cursor: "pointer" }}
									onClick={() => this.changeLockStatus(record)}>
									&#xe63e;
								</span>
							) : (
								<span
									className='icon iconfont'
									style={{ marginLeft: 8, cursor: "pointer" }}
									onClick={() => this.changeLockStatus(record)}>
									&#xe62e;
								</span>
							)}
						</>
					);
				},
			},
			{
				title: "联系电话",
				width: "8%",
				dataIndex: "phone",
				key: "phone",
			},
			{
				title: "注册位置",
				width: "15%",
				dataIndex: "regIp",
				key: "regIp",
				render: (text, record) => <span>{text + "[" + record.regAddr + "]"}</span>,
			},
			{
				title: "开通时间",
				width: "10%",
				dataIndex: "startTime",
				key: "startTime",
				align: "center",
				render: (text) => (
					<Tooltip placement='top' title={text}>
						<span>{text ? moment(text).format("YYYY-MM-DD") : "--"}</span>
					</Tooltip>
				),
			},
			{
				title: "到期时间",
				width: "10%",
				dataIndex: "endTime",
				key: "endTime",
				align: "center",
				render: (text) => (
					<Tooltip placement='top' title={text}>
						<span>{text ? moment(text).format("YYYY-MM-DD") : "--"}</span>
					</Tooltip>
				),
			},
			{
				title: "最近登录",
				width: "15%",
				dataIndex: "loginTime",
				key: "loginTime",
				align: "center",
				render: (text) => (
					<Tooltip placement='top' title={text}>
						<span>{text ? moment(text).format("YYYY-MM-DD") : "--"}</span>
					</Tooltip>
				),
			},
		];
	};
	changeLockStatus = (data) => {
		let _this = this;

		let lockFlag = data.isLock === "0" ? "锁定" : "解锁";
		confirm({
			title: data.isLock === "0" ? "警告：锁定商户" : "警告：解锁商户",
			content: data.isLock === "0" ? "锁定后商户将无法登陆" : "解锁后商户将恢复登陆",
			okText: data.isLock === "0" ? "确定锁定" : "确定解锁",
			cancelText: "取消",
			onOk() {
				_this.handleLockChange(data);
			},
		});
	};
	handleLockChange = (data) => {
		// console.log(data);
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token } = this.props;
		let body = {
			id: data.id,
			is_lock: data.isLock === "0" ? "lock" : "open",
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_lock&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response === "200") {
					message.success(res.data.msg);
					this.fetchMsgList();
				} else {
					message.info(res.data.msg);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	deleteConfirm = (data) => {
		let _this = this;
		confirm({
			title: "警告：删除商户信息",
			content: "删除后，所有有关本商户信息将被删除，且无法恢复，确认删除吗?",
			okText: "确定删除",
			cancelText: "取消",
			onOk() {
				_this.handleDelete(data);
			},
		});
	};
	handleDelete = (data) => {
		console.log(data, "删除确认");
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token } = this.props;
		const body = { id: data, user_type: "admin" };
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_delete&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response === "200") {
					message.success(res.data.msg);
					this.fetchMsgList();
				} else {
					message.error(res.data.msg);
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	render() {
		const { match } = this.props;
		const { name, query, totalCount, manageData } = this.state;
		const { current, size, type, beginTime, loginTime, endTime, manager, commision, progress } = query;
		return (
			<div className='page'>
				<div className='page-content'>
					<div className='content-header'>
						<h1>商户管理</h1>
					</div>
					<div className='box'>
						<div className='box-header'>
							<Row type='flex' justify='space-around' align='top'>
								<Col>
									<SearchBar
										searchText={name}
										onSearchTextInput={(e) => {
											this.setState({ name: e });
										}}
										onClick={this.handleQuery}
										label='搜索商户'
										placeholder='手机号/名称/商户ID'
									/>
									<div className='common-select staticSelect'>
										<div className='label'>商户类型：</div>
										<Select
											placeholder='商户类型'
											allowClear
											value={type || undefined}
											onChange={(e) => {
												this.setState(
													{
														query: Object.assign({}, query, { type: e }),
													},
													() => this.handleQuery()
												);
											}}>
											<Option key='0' value='10'>
												永久免费版
											</Option>
											<Option key='1' value='100'>
												单门店版
											</Option>
											<Option key='2' value='500'>
												五门店版
											</Option>
											<Option key='3' value='999'>
												不限门店版
											</Option>
											{/*<Option key='4' value='202'>
												旗舰版
											</Option>*/}
										</Select>
									</div>
									<div className='common-select staticSelect'>
										<div className='label'>开通时间：</div>
										<Select
											placeholder='开通时间'
											allowClear
											value={beginTime || undefined}
											onChange={(e) => {
												this.setState(
													{
														query: Object.assign({}, query, { beginTime: e }),
													},
													() => this.handleQuery()
												);
											}}>
											<Option key='1' value='2'>
												当天
											</Option>
											<Option key='2' value='7'>
												一周内
											</Option>
											<Option key='3' value='30'>
												一月内
											</Option>
											<Option key='4' value='365'>
												一年内
											</Option>
										</Select>
									</div>
									<div className='common-select staticSelect'>
										<div className='label'>登录时间：</div>
										<Select
											placeholder='登录时间'
											allowClear
											value={loginTime || undefined}
											onChange={(e) => {
												this.setState(
													{
														query: Object.assign({}, query, { loginTime: e }),
													},
													() => this.handleQuery()
												);
											}}>
											<Option key='1' value='2'>
												当天
											</Option>
											<Option key='2' value='7'>
												一周内
											</Option>
											<Option key='3' value='30'>
												一月内
											</Option>
											<Option key='4' value='365'>
												一年内
											</Option>
										</Select>
									</div>
									<div className='common-select staticSelect'>
										<div className='label'>到期时间：</div>
										<Select
											placeholder='到期时间'
											allowClear
											value={endTime || undefined}
											onChange={(e) => {
												this.setState(
													{
														query: Object.assign({}, query, { endTime: e }),
													},
													() => this.handleQuery()
												);
											}}>
											<Option key='1' value='2'>
												当天
											</Option>
											<Option key='2' value='7'>
												一周内
											</Option>
											<Option key='3' value='30'>
												一月内
											</Option>
											<Option key='4' value='365'>
												一年内
											</Option>
										</Select>
									</div>
								</Col>
							</Row>
						</div>
						<div className='box-body'>
							<Table
								rowKey={(record) => record.id}
								size='middle'
								pagination={false}
								dataSource={this.state.dataSet}
								columns={this.parseColumn()}
								bordered
							/>
						</div>
						<div className='box-footer'>
							<PageControl
								current={current}
								pageSize={size}
								total={totalCount}
								toPage={(a) => {
									this.setState({ query: Object.assign({}, query, { current: a }) }, () => {
										this.fetchMsgList();
									});
								}}
							/>
						</div>
					</div>
				</div>
				<Switch>
					<Route path={`${match.url}/detail/:id`} component={OrganizationManageDetail} />
				</Switch>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(OrganizationManage);
