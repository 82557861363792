import React, { Component } from 'react'
import { Select } from 'antd';
import './StaticSelect.less'

const { Option } = Select;

export default class StaticSelect extends Component{
    state = {
        selectValue:'',
        label: '',
        options: [],
        placeholder: 'zx'
    };

    componentDidMount() {
        const { options,selectValue } = this.props;
        this.setState({
            label: this.props.label,
            placeholder: this.props.placeholder,
            options: options,
            selectValue: selectValue,
        });
    }
    componentWillReceiveProps(nextProps) {
        const { options,selectValue } = nextProps;
        this.setState({
            options: options,
            selectValue: selectValue,
        })
    }

    handleChange = (value) => {
        this.props.onHandleChange(value)
        setTimeout(()=>{
            this.props.onSelect()
        },1)
    }

    render() {
        const { label, options, placeholder, selectValue } = this.state;
        return(
            <div className="common-select staticSelect">
                {
                    this.props.label &&
                    <div className="label"> {label}:</div>
                }
                <Select onPopupScroll={()=>{
                    console.log("sssssss")
                }} value={selectValue ? selectValue : undefined} placeholder={placeholder} onChange={this.handleChange} allowClear={true}>
                    {options?options.map((option) =>(
                        <Option key={option.id} value={option.id}>{option.name}</Option>
                    )):null}
                </Select>
            </div>
        )
    }
}
