import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (auth.isLoading) {
        return (
          <div className="loader fullScreen">
            <div className="warpper">
              <div className="inner"></div>
              <span className="text">网页载入中...</span>
            </div>
          </div>
        );
      } else if (!auth.isAuthenticated && auth.isAuthenticated != null) {
        return <Redirect to="/login" />;
      } else {
        return <Component {...props} />;
      }
    }}
  />
);
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
