import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";
import { Input, Dropdown, Menu, Avatar, message } from "antd";
import $ from "jquery";

import { logout, resetUserInfo } from "../../actions/auth";
import { getWxList } from "../../actions/wechat";
import { toggleCollapsed } from "../../actions/menu";
import logo_small from "../../static/images/logo_small.png";
import logo_big from "../../static/images/logo_big.png";
import store from "../../store";

import "../../modules/root/Header.less";

export class Header extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    toggleCollapsed: PropTypes.func.isRequired,
  };
  state = {
    collapsed: false,
    text: "",
  };
  handleEnter = (e) => {
    let text = e.target.value ? e.target.value.replace(/^\s+/gm, "") : "";
    this.setState({ text });
  };

  Search = () => {
    console.log("sss");
    $(window.document).trigger("entity", [this.state.text]);
    this.props.history.push({
      pathname: "/integrated_query",
      state: { name: this.state.text },
    });
  };

  getCurrentUser = () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const token = this.props.token;
    axios
      .post(
        "https://api.sanfeng9.com/admin/admin.php?cmd=cur_userinfo&token=" +
          token,
        "user_type=admin",
        config
      )
      .then((res) => {
        if (res.data.response === "200") {
          console.log(res);
          let data = res.data.msg;
          this.setState({
            name: data.realname,
            head_img: data.adminface,
          });
        } else {
          console.log(res.data.msg);
        }
      });
  };
  onlineSwitch() {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const token = this.props.token;
    axios
      .post(
        "https://api.sanfeng9.com/admin/admin.php?cmd=online_set&token=" +
          token,
        "user_type=admin",
        config
      )
      .then((res) => {
        console.log(res);
        if (res.data.response === "200") {
          message.success(res.data.msg);
          this.props.getWxList();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  componentDidMount() {
    this.props.resetUserInfo();
    this.props.getWxList();
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { collapsed, text, name } = this.state;
    const curOnline = this.props.wxList.find(
      (item) => item.realname === this.props.user.realname
    )?.online;
    const prefix = (
      <span style={{ color: "#8C8E93" }} className="icon iconfont ">
        &#xe688;
      </span>
    );
    const suffix = this.state.text ? (
      <span
        style={{ color: "#8C8E93" }}
        onClick={() => this.setState({ text: "" })}
        className="icon iconfont "
      >
        &#xe601;
      </span>
    ) : null;
    const menu = (
      <Menu style={{ maxWidth: "250px", marginTop: "5px" }}>
        <Menu.Item key="0">
          <Link to="/user_info">个人账号</Link>{" "}
        </Menu.Item>
      </Menu>
    );

    return (
      <div id="top-container" className="top-container">
        <div className="top top-nav">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.props.history.push("/");
            }}
          >
            {collapsed ? (
              <img src={logo_small} className="logo" alt="" />
            ) : (
              <img src={logo_big} className="logo" alt="" />
            )}
          </div>
          <div style={{ position: "relative" }}>
            <Input
              placeholder="手机号/名称/商户ID"
              prefix={prefix}
              suffix={suffix}
              autoComplete="off"
              name="text"
              type="text"
              value={text}
              onChange={this.handleEnter}
              onPressEnter={() => {
                this.Search();
              }}
            />
          </div>

          <div className="message-help">
            <div
              onClick={() => this.onlineSwitch()}
              className="wx-online info-group"
              style={{
                backgroundColor: curOnline === "1" ? "#54B536" : "#E0E0E0",
                marginRight: "50px",
              }}
            >
              <span
                className="icon iconfont"
                style={{ color: "#fff", fontSize: "24px" }}
              >
                &#xe61a;
              </span>
              <span
                style={{
                  fontSize: "14px",
                  color: curOnline === "1" ? "#fff" : "#ABABAB",
                  marginLeft: "2px",
                }}
              >
                {curOnline === "1" ? "在线" : "离线"}
              </span>
            </div>
            <Dropdown overlay={menu} className="user">
              <div className="info-group">
                <Avatar
                  size="large"
                  src={this.props.user ? this.props.user.adminface : logo_small}
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    flexShrink: "0",
                  }}
                />
                <span className="name">
                  {this.props.user ? this.props.user.realname : "尚未注册"}
                </span>
                <span className="icon iconfont" style={{ color: "#5D5D5D" }}>
                  &#xe60e;
                </span>
              </div>
            </Dropdown>
            <div
              className="info-group"
              style={{ marginLeft: "20px" }}
              onClick={() => {
                window.open("https://www.sanfeng9.com");
              }}
            >
              <span
                className="icon iconfont"
                style={{
                  color: "#5D5D5D",
                  fontSize: "22px",
                  marginRight: "3px",
                }}
              >
                &#xe639;
              </span>
              <span style={{ color: "#454545", fontSize: "14px" }}>官网</span>
            </div>
            <div
              className="info-group"
              style={{ marginLeft: "40px" }}
              onClick={this.props.logout}
            >
              <span
                className="icon iconfont"
                style={{
                  color: "#5D5D5D",
                  fontSize: "22px",
                  marginRight: "3px",
                }}
              >
                &#xe612;
              </span>
              <span style={{ color: "#454545", fontSize: "14px" }}>退出</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  token: state.auth.token,
  user: state.auth.user,
  wxList: state.wechat.wxList,
});

export default withRouter(
  connect(mapStateToProps, {
    logout,
    toggleCollapsed,
    resetUserInfo,
    getWxList,
  })(Header)
);
