import React, { Component } from "react";
import {Row, Col, Button, Modal, Select, Table, Input, InputNumber, Radio, message, Form} from "antd/lib/index";
import axios from "axios/index";
import qs from "qs";
import PageControl from "../common/PageControl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import E from "wangeditor";
import './Add.less'

const { Option } = Select;

export class TypeModal extends Component {

    state = {
        value:this.props.isEdit
            ? this.props.value
            : ''
    };

    handleOk = () => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props;
        if(this.props.isEdit){
            const body = {
                type_id: this.props.id,
                title_new: this.state.value,
                user_type:"admin"
            };
            axios
                .post("https://api.sanfeng9.com/admin/help.php?cmd=type_edit&token="+token, qs.stringify(body), config)
                .then(res => {
                    if(res.data.response == 200){
                        this.props.success();
                        this.props.close()
                    }else{
                        message.error(res.data.msg)
                    }
                })
                .catch(err => {
                    console.log(err)
                });
        }else{
            const body = {
                pid: this.props.id,
                title: this.state.value,
                user_type:"admin"
            };
            axios
                .post("https://api.sanfeng9.com/admin/help.php?cmd=type_add&token="+token, qs.stringify(body), config)
                .then(res => {
                    if(res.data.response == 200){
                        this.props.success();
                        this.props.close()
                    }else{
                        message.error(res.data.msg)
                    }
                })
                .catch(err => {
                    console.log(err)
                });
        }
    };

    render() {
        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 12 },
        };
        const { value } = this.state;
        console.log(value)
        return (
            <Modal
                title={this.props.isEdit ? '修改类别名称' : '添加子类菜单'}
                className="type-modal"
                width={400}
                visible={true}
                onOk={this.handleOk}
                onCancel={this.props.close}
                okText= '确定'
                cancelText='取消'
            >
                <Form
                    {...layout}
                    name="basic"
                >
                    <Form.Item
                        label="类别名称"
                        name="name"
                    >
                        <Input defaultValue={value} value={value} onChange={(e)=>{
                            this.setState({
                                value:e.target.value
                            })
                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}
const mapStateToProps = state => ({
    token: state.auth.token,
});
export default connect(
    mapStateToProps
)(TypeModal);
