import React, { Component } from "react";
import { Row, Col, Button, Modal, Select, Table, Input, Tree, message } from "antd/lib/index";
import axios from "axios/index";
import qs from "qs";
import {connect} from "react-redux";
import './Type.less'
import TypeModal from './TypeModal'

export class Type extends Component {

    state = {
        treeData:[],
        modal:null
    };

    componentDidMount() {
        this.getTypeData()
    }

    paixu = (id,paixu) => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props;
        const body = {
            type_id: id,
            paixu: paixu,
            user_type:"admin"
        };
        axios
            .post("https://api.sanfeng9.com/admin/help.php?cmd=type_edit&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    this.getTypeData()
                }else{
                    message.error(res.data.msg)
                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    addConfirm = (id) => {
        let modal = <TypeModal id={id} success={()=>this.getTypeData()} close={()=>{
            this.setState({modal:null})
        }} />;
        this.setState({
            modal
        })
    };

    deleteConfirm = (id) => {
        let _this = this;
        Modal.confirm({
            title: '删除',
            content: '如有下级菜单将会一起删除，是否确定？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                _this.delete(id)
            },
            onCancel() {

            },
        });
    };
    delete = (id) => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props
        const body = {
            type_id:id,
            user_type:"admin"
        }
        axios
            .post("https://api.sanfeng9.com/admin/help.php?cmd=type_del&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    message.success(res.data.msg);
                    this.getTypeData()
                }else{
                    Modal.warning({
                        title: '提示信息',
                        content: res.data.msg,
                        maskClosable:true
                    });
                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    editConfirm = (id,value) => {
        let modal = <TypeModal id={id} isEdit={true} value={value} success={()=>this.getTypeData()} close={()=>{
            this.setState({modal:null})
        }} />;
        this.setState({
            modal
        })
    };

    renderMapTree = (org,index,length) => {
        const haveChildren = Array.isArray(org.s_type) && org.s_type.length > 0;
        if(haveChildren){
            return (
                [
                    <Row justify="space-between">
                        <Col>
                            {
                                index === 0 ?
                                    <a className="iconfont icon" onClick={this.paixu.bind(this,org.id,'down')}>&#xe698;</a> :
                                    <React.Fragment>
                                        {
                                            index === length - 1 ?
                                                <a className="iconfont icon" onClick={this.paixu.bind(this,org.id,'up')}>&#xe694;</a> :
                                                [
                                                    <a className="iconfont icon" onClick={this.paixu.bind(this,org.id,'up')}>&#xe694;</a>,
                                                    <a className="iconfont icon" onClick={this.paixu.bind(this,org.id,'down')}>&#xe698;</a>
                                                ]
                                        }
                                    </React.Fragment>
                            }
                            <span>{org.title}</span>
                        </Col>
                        <Col>
                            <a onClick={this.addConfirm.bind(this,org.id)}>添加</a>
                            <a onClick={this.editConfirm.bind(this,org.id,org.title)}>修改</a>
                            <a onClick={this.deleteConfirm.bind(this,org.id)}>删除</a>
                        </Col>
                    </Row>,
                    <div style={{marginLeft:20}}>
                        {
                            org.s_type.map(i => this.renderMapTree(i))
                        }
                    </div>
                ]
            )
        }else{
            return (
                <Row justify="space-between">
                    <Col>
                        {
                            index === 0 ?
                                <a className="iconfont icon" onClick={this.paixu.bind(this,org.id,'down')}>&#xe698;</a> :
                                <React.Fragment>
                                    {
                                        index === length - 1 ?
                                            <a className="iconfont icon" onClick={this.paixu.bind(this,org.id,'up')}>&#xe694;</a> :
                                            [
                                                <a className="iconfont icon" onClick={this.paixu.bind(this,org.id,'up')}>&#xe694;</a>,
                                                <a className="iconfont icon" onClick={this.paixu.bind(this,org.id,'down')}>&#xe698;</a>
                                            ]
                                    }
                                </React.Fragment>
                        }
                        <span>{org.title}</span>
                    </Col>
                    <Col>
                        <a onClick={this.addConfirm.bind(this,org.id)}>添加</a>
                        <a onClick={this.editConfirm.bind(this,org.id,org.title)}>修改</a>
                        <a onClick={this.deleteConfirm.bind(this,org.id)}>删除</a>
                    </Col>
                </Row>
            )
        }

    }

    getTypeData = () => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token } = this.props;
        axios
            .post("https://api.sanfeng9.com/shop/public.php?cmd=help_get_typelist&token="+token, 'user_type=admin', config)
            .then(res => {
                if(res.data){
                    let data = res.data;
                    this.setState({
                        treeData:data
                    })
                }else{

                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    render() {
        const { treeData,modal } = this.state;
        return (
            <div className="help-type">
                {
                    treeData.map((item,index)=>this.renderMapTree(item,index,treeData.length))
                }
                {modal}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    token: state.auth.token,
});
export default connect(
    mapStateToProps
)(Type);
