import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { createHashHistory } from "history";
import axios from "axios";
import "../../modules/root/Menu.less";

const { SubMenu } = Menu;
const hashHistory = createHashHistory();

export class Nav extends Component {
  state = {
    menu: [],
    openKeys: [],
    rootSubmenuKeys: [],
    current: [],
    homeItem: [
      {
        name: "中心首页",
        link: "home",
        parent: "home",
      },
    ],
  };

  componentDidMount() {
    this.init();
  }
  componentWillReceiveProps(nextProps) {
    const { action } = nextProps;
    const { menu } = this.state;
    let data = [...menu];
    data.push({
      name: "中心首页",
      link: "home",
      parent: "home",
    });
    data.push({
      name: "中心首页",
      link: "user_info",
      parent: "home",
    });
    data.push({
      name: "中心首页",
      link: "",
      parent: "home",
    });
    const current = action.location.pathname.split("/")[1];
    console.log(current);
    if (current === "user_info" || current === "") {
      return this.setState({ current: ["home"], openKeys: ["home"] });
    } else {
      this.setState({ current: [current] });
    }
    let activeKeyList = data.filter((item) => item.link == current);
    console.log(activeKeyList);
    this.setState({ openKeys: [activeKeyList[0].parent] });
  }

  rootSubmenuKeys = [];

  init = () => {
    //设置menu
    const { action, token } = this.props;
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    axios
      .post(
        "https://api.sanfeng9.com/admin/main.php?cmd=get_user_menu&token=" +
          token,
        "user_type=admin",
        config
      )
      .then((res) => {
        console.log("getmenu", res);
        if (res.data.response === "200") {
          res.data.msg.map((menu, index) => {
            if (menu.parent == 0) {
              this.rootSubmenuKeys.push(menu.code);
            }
          });
          this.setState(
            {
              menu: res.data.msg,
            },
            () => {
              const { action } = this.props;
              const { menu } = this.state;
              let data = [...menu];
              data.push({
                name: "中心首页",
                link: "home",
                parent: "home",
              });
              data.push({
                name: "中心首页",
                link: "user_info",
                parent: "home",
              });
              data.push({
                name: "中心首页",
                link: "",
                parent: "home",
              });
              const current = action.location.pathname.split("/")[1];
              if (current === "user_info" || current === "") {
                this.setState({ current: ["home"] });
              } else {
                this.setState({ current: [current] });
              }
              let activeKeyList = data.filter((item) => item.link == current);
              this.setState({ openKeys: [activeKeyList[0].parent] });
            }
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    //设置current
    const current = action.location.pathname.split("/")[1];
    if (current === "home" || current === "") {
      this.setState({
        current: ["home"],
      });
    } else {
      this.setState({
        current: [current],
      });
    }
  };
  handleClick = (e) => {
    // console.log(e.key);
    const { match } = this.props.action;
    this.setState({
      current: e.key,
    });
    if (e.key === "home") {
      this.setState({ openKeys: ["home"] });
      hashHistory.push(`${match.url}home`);
    } else {
      hashHistory.push(`${match.url}${e.key}`);
    }
  };
  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    // console.log(latestOpenKey);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { menu } = this.state;
    return (
      <div className="left-container">
        <div className="left">
          <div className="nav">
            <Menu
              onClick={this.handleClick}
              openKeys={this.state.openKeys}
              selectedKeys={this.state.current}
              onOpenChange={this.onOpenChange}
              mode="inline"
            >
              <Menu.Item key="home" className="fistLevel homeBar">
                <span className="home-title">
                  <span className="title">
                    <span className="iconfont icon">&#xe707;</span>
                    <span className="menu-name" id="menu_home">
                      中心首页
                    </span>
                  </span>
                </span>
              </Menu.Item>
              {menu.map((Item) =>
                Item.parent === "0" ? (
                  <SubMenu
                    className="fistLevel"
                    key={Item.code}
                    title={
                      <span>
                        <span className="title">
                          <span
                            className="iconfont icon"
                            dangerouslySetInnerHTML={{
                              __html: Item.icon,
                            }}
                          ></span>
                          <span className="menu-name">{Item.name}</span>
                        </span>
                      </span>
                    }
                  >
                    {menu.map((item) =>
                      Item.code === item.parent ? (
                        <Menu.Item key={item.link}>
                          <div>
                            <span className="listType"></span>
                            <span className="name" id={item.link}>
                              {item.name}
                            </span>
                          </div>
                        </Menu.Item>
                      ) : null
                    )}
                  </SubMenu>
                ) : null
              )}
            </Menu>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  menuFold: state.menu.menuFold,
});

export default connect(mapStateToProps)(Nav);
