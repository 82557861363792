import React, { Component } from "react";
import { Pagination } from "antd/lib/index";
import "./PageControl.less";

export default class PageControl extends Component {
	render() {
		const {
			current,
			total,
			pageSize,
			showDataNums,
			showSizeChanger,
			size,
			toPage,
			className,
			onShowSizeChange,
		} = this.props;
		const newClassName = "sf-page-control " + className;
		return (
			<div className={newClassName}>
				{(undefined === showDataNums || showDataNums) && <span>共{total}条数据</span>}
				<div style={{ display: "inline", float: "right" }}>
					<Pagination
						size={size}
						current={current}
						total={total || 0}
						pageSizeOptions={["10", "20", "30", "40", "50"]}
						onShowSizeChange={onShowSizeChange}
						showSizeChanger={showSizeChanger}
						pageSize={pageSize || 10}
						onChange={(e) => {
							toPage(e);
						}}
						showQuickJumper={true}
					/>
				</div>
			</div>
		);
	}
}
