import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Avatar, Input, Row, Col, Button, message, Modal } from "antd";
import axios from "axios";
import qs from "qs";
import ImgCropper from "../common/ImgCropper";

import "../../modules/root/UserInfo.less";
import store from "../../store";
import { resetUserInfo } from "../../actions/auth";

export class UserInfo extends Component {
  state = {
    passwd_pre: "",
    passwd_new1: "",
    passwd_new2: "",
  };
  handlePasswdReset = () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const token = this.props.token;
    const { passwd_new1, passwd_new2, passwd_pre } = this.state;
    const body = {
      old_password: passwd_pre,
      new_password: passwd_new1,
      renew_password: passwd_new2,
      user_type: "admin",
    };
    axios
      .post(
        "https://api.sanfeng9.com/admin/admin.php?cmd=password_reset&token=" +
          token,
        qs.stringify(body),
        config
      )
      .then((res) => {
        if (res.data.response === "200") {
          message.success(res.data.msg);
          this.setState({
            passwd_pre: "",
            passwd_new1: "",
            passwd_new2: "",
          });
        } else {
          Modal.warning({
            title: "提示信息",
            content: res.data.msg,
            maskClosable: true,
          });
        }
      });
  };
  setCurrentUser = () => {
    let stateUser = store.getState().auth.user;
    const { realname, qq, phone, adminface } = stateUser || {};
    this.setState({
      name: realname,
      head_img: adminface,
      qq: qq,
      phone: phone,
    });
  };
  componentDidMount() {
    this.setCurrentUser();
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const layout = {
      labelCol: { span: 2 },
    };
    const { passwd_pre, passwd_new1, passwd_new2 } = this.state;
    return (
      <div className="page user-info">
        <div className="page-content">
          <div className="content-header">
            <h1>个人信息</h1>
          </div>
        </div>
        <div className="box first">
          <Form {...layout}>
            <header className="title">个人信息</header>
            <Form.Item label="头像">
              <span id="avatar_edit">
                <span className="up-load-content">
                  <span
                    className="upload"
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                    }}
                  >
                    <Avatar
                      style={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                      }}
                      src={this.props.user && this.props.user.adminface}
                      size="large"
                      icon={
                        <span
                          className="icon iconfont"
                          style={{
                            fontSize: "20px",
                            verticalAlign: "middle",
                          }}
                        >
                          &#xe678;
                        </span>
                      }
                    />
                  </span>
                  <div
                    className="edit"
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      this.setState({ visible: true });
                    }}
                  >
                    <span className="icon iconfont">&#xe60c;</span>
                  </div>
                </span>
              </span>
              <ImgCropper
                type={"admin_head"}
                visible={this.state.visible}
                src={this.props.user && this.props.user.adminface}
                onHandleOk={() => {
                  this.setState({ visible: false });
                }}
                onHandleCancel={() => {
                  this.setState({ visible: false });
                }}
                onHandleSuccess={(url) => {
                  store.dispatch(resetUserInfo());
                  this.setState({ head_img: url });
                }}
              />
            </Form.Item>
            <Form.Item label="姓名">
              <span>{this.props.user && this.props.user.realname}</span>
            </Form.Item>
            <Form.Item label="手机">
              <span>{this.props.user && this.props.user.phone}</span>
            </Form.Item>
            <Form.Item label="Q Q">
              <span>{this.props.user && this.props.user.qq}</span>
            </Form.Item>
            <Row>
              <Col>
                <span
                  className="icon iconfont"
                  style={{
                    color: "rgb(32, 161, 255)",
                    marginRight: "10px",
                  }}
                >
                  &#xe606;
                </span>
                个人信息仅作展示，如需变更请联系部门主管处理。
              </Col>
            </Row>
          </Form>
        </div>
        <div className="box">
          <header className="title">修改密码</header>
          <Form {...layout}>
            <Form.Item label="旧密码">
              <Input.Password
                autoComplete="new-password"
                placeholder="请输入目前的登陆密码"
                value={passwd_pre}
                onChange={(e) =>
                  this.setState({
                    passwd_pre: e.target.value,
                  })
                }
                style={{ width: "265px" }}
              />
            </Form.Item>
            <Form.Item label="新密码">
              <Input.Password
                autoComplete="new-password"
                placeholder="6-16位数字或字母，区分大小写"
                value={passwd_new1}
                onChange={(e) =>
                  this.setState({
                    passwd_new1: e.target.value,
                  })
                }
                style={{ width: "265px" }}
              />
            </Form.Item>
            <Form.Item label="重复新密码">
              <Input.Password
                autoComplete="new-password"
                placeholder="6-16位数字或字母，区分大小写"
                value={passwd_new2}
                onChange={(e) =>
                  this.setState({
                    passwd_new2: e.target.value,
                  })
                }
                style={{ width: "265px" }}
              />
            </Form.Item>
            <Row style={{ marginTop: "20px" }}>
              <Col span={2}></Col>
              <Col span={3}>
                <Button type="primary" onClick={this.handlePasswdReset}>
                  重置密码
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    this.setState({
                      passwd_pre: "",
                      passwd_new1: "",
                      passwd_new2: "",
                    });
                  }}
                >
                  取消
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
});
export default connect(mapStateToProps)(UserInfo);
