import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Input } from "antd";

import { login } from "../../actions/auth";

import "../../modules/root/Login.less";
import mainBanner from "../../static/images/main_banner.png";
import userLogo from "../../static/images/user_logo.png";
import pwLogo from "../../static/images/pw_logo.png";
import titleLogo from "../../static/images/title_logo.png";

export class Login extends Component {
	state = {
		phone: "",
		passwd: "",
	};
	componentDidMount() {
		console.log(this.props);
		// document.body.addEventListener('keydown',this.onSubmit)
	}
	componentWillUnmount() {
		// document.body.removeEventListener('keydown',this.onSubmit)
	}
	onSubmit = () => {
		this.props.login(this.state.phone, this.state.passwd);
	};
	render() {
		if (this.props.isAuthenticated) {
			return <Redirect to='/' />;
		}
		return (
			<div
				className='login-page'
				style={{
					background: `url(${mainBanner})  0% 0% / 100% no-repeat #0049B1`,
				}}>
				<div className='login-main'>
					<div className='login-box' style={{ backgroundColor: "transparent" }}>
						<div className='form'>
							<div
								className='title_log'
								style={{
									background: `url(${titleLogo})  0% 0% / 100% no-repeat`,
								}}></div>
							<div className='form-item'>
								{/* <Input
									value={this.state.phone}
									onChange={(e) => {
										this.setState({
											phone: e.target.value,
										});
									}}
									autoComplete='off'
									placeholder='账号'
									onPressEnter={this.onSubmit}
								/> */}
								<input
									id='username'
									type='text'
									value={this.state.phone}
									onChange={(e) => {
										this.setState({
											phone: e.target.value,
										});
									}}
									style={{
										background: `url(${userLogo}) 20px 8px no-repeat`,
									}}
									autoComplete='off'
									placeholder='账号'
								/>
							</div>
							{/* <div className='form-item'>
								<Input.Password
									style={{ color: "#000" }}
									value={this.state.passwd}
									onChange={(e) => {
										console.log(e.target.value);
										this.setState({
											passwd: e.target.value,
										});
									}}
									placeholder='密码'
									onPressEnter={this.onSubmit}
								/>
							</div> */}
							<div className='form-item'>
								<input
									id='password'
									type='password'
									value={this.state.passwd}
									onChange={(e) => {
										this.setState({
											passwd: e.target.value,
										});
									}}
									style={{
										background: `url(${pwLogo}) 20px 11px no-repeat`,
									}}
									autoComplete='off'
									placeholder='密码'
								/>
							</div>
							<div className='form-item  submit'>
								<button id='submit' onClick={this.onSubmit}>
									登 录
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
