import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import qs from "qs";
import { Form, Input, message, Modal, Radio, Avatar } from "antd";
import ImgCropper from "../../common/ImgCropper";

import "../../../modules/common/Upload.less";
const { TextArea } = Input;
export class AccountInfoEditModal extends Component {
	state = {
		info: this.props.isEdit
			? { ...this.props.info }
			: {
					id: "",
					adminface: "",
					realname: "",
					phone: "",
					qq: "",
					groupname: "",
					remark: "",
					password: "",
			  },
	};
	handleOk = () => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token, isEdit } = this.props;
		const { info } = this.state;
		let body;
		if (isEdit) {
			body = {
				id: info.id,
				realname: info.realname,
				adminface: info.adminface,
				phone: info.phone,
				groupname: info.groupname,
				qq: info.qq,
				password: info.password,
				remark: info.remark,
				user_type: "admin",
			};
		} else {
			body = {
				realname: info.realname,
				adminface: info.adminface,
				phone: info.phone,
				groupname: info.groupname,
				qq: info.qq,
				password: info.password,
				remark: info.remark,
				user_type: "admin",
			};
		}
		axios
			.post("https://api.sanfeng9.com/admin/admin.php?cmd=admin_add&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response === "200") {
					message.success(res.data.msg);
					this.props.onSelectColumns();
					setTimeout(() => {
						this.props.onHandleCancel();
					}, 100);
				} else {
					Modal.warning({
						title: "提示信息",
						content: res.data.msg,
						maskClosable: true,
					});
				}
			});
	};
	render() {
		const { info } = this.state;
		const formItemLayout = {
			labelCol: {
				span: 6,
			},
			wrapperCol: {
				span: 18,
			},
		};

		return (
			<Modal
				title={this.props.isEdit ? "编辑账号信息" : "新增账户信息"}
				visible={true}
				okText='确定'
				cancelText='取消'
				onOk={this.handleOk}
				onCancel={this.props.onHandleCancel}
				style={{ width: 520 }}>
				<Form {...formItemLayout}>
					<Form.Item label='头像'>
						<span id='avatar_edit'>
							<span className='up-load-content'>
								<span
									className='upload'
									style={{
										width: "48px",
										height: "48px",
										borderRadius: "50%",
									}}>
									<Avatar
										style={{
											width: "48px",
											height: "48px",
											borderRadius: "50%",
										}}
										src={info.adminface}
										size='large'
										icon={
											<span
												className='icon iconfont'
												style={{
													fontSize: "20px",
													verticalAlign: "middle",
												}}>
												&#xe678;
											</span>
										}
									/>
								</span>
								<div
									className='edit'
									style={{
										width: "48px",
										height: "48px",
										borderRadius: "50%",
									}}
									onClick={() => {
										this.setState({ visible: true });
									}}>
									<span className='icon iconfont'>&#xe60c;</span>
								</div>
							</span>
						</span>
						<ImgCropper
							type='admin_head'
							visible={this.state.visible}
							id={info.id}
							src={info.adminface}
							onHandleOk={() => {
								this.setState({ visible: false });
							}}
							onHandleCancel={() => {
								this.setState({ visible: false });
							}}
							onHandleSuccess={(url) => {
								this.setState({
									info: Object.assign({}, info, {
										adminface: url,
									}),
								});
							}}
						/>
					</Form.Item>
					<Form.Item label='姓名'>
						<Input
							id='name'
							maxLength={40}
							name='info.realname'
							placeholder='请输入真实姓名'
							type='text'
							onChange={(e) => {
								this.setState({
									info: Object.assign({}, info, {
										realname: e.target.value,
									}),
								});
							}}
							value={info.realname}
							style={{ width: 250 }}
						/>
					</Form.Item>
					<Form.Item label='手机'>
						<Input
							id='phone'
							maxLength={40}
							name='info.phone'
							placeholder='请输入手机号码'
							type='text'
							onChange={(e) => {
								this.setState({
									info: Object.assign({}, info, {
										phone: e.target.value,
									}),
								});
							}}
							value={info.phone}
							style={{ width: 250 }}
						/>
					</Form.Item>
					<Form.Item label='Q Q'>
						<Input
							id='qq'
							maxLength={40}
							name='info.qq'
							placeholder='请输入QQ号码'
							type='text'
							onChange={(e) => {
								this.setState({
									info: Object.assign({}, info, {
										qq: e.target.value,
									}),
								});
							}}
							value={info.qq}
							style={{ width: 250 }}
						/>
					</Form.Item>
					<Form.Item label='备注'>
						<TextArea
							id='remark'
							maxLength={200}
							name='info.remark'
							placeholder='请输入备注信息(最多200字)'
							onChange={(e) => {
								this.setState({
									info: Object.assign({}, info, {
										remark: e.target.value,
									}),
								});
							}}
							value={info.remark}
							style={{ width: 250 }}
						/>
					</Form.Item>
					<Form.Item label='重置密码'>
						<Radio.Group
							value={info.password ? true : false}
							onChange={(e) => {
								if (e.target.value) {
									let textRent = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
										a = 62,
										data = "";
									for (let i = 0; i < 8; i++) {
										data += textRent.charAt(Math.floor(Math.random() * a));
									}
									this.setState({
										info: Object.assign({}, info, {
											password: data,
										}),
									});
								} else {
									this.setState({
										info: Object.assign({}, info, {
											password: "",
										}),
									});
								}
							}}>
							<Radio value={true}>是</Radio>
							<Radio value={false}>否</Radio>
						</Radio.Group>
						{info.password ? (
							<span style={{ fontSize: "12px", color: "red" }}>{"您的密码为：" + info.password}</span>
						) : null}
					</Form.Item>
					<Form.Item label='分组'>
						<Radio.Group
							value={info.groupname}
							onChange={(e) => {
								this.setState({
									info: Object.assign({}, info, {
										groupname: e.target.value,
									}),
								});
							}}>
							<Radio value='管理员'>管理员</Radio>
							<Radio value='财务'>财务</Radio>
							<Radio value='销售'>销售</Radio>
							<Radio value='客服'>客服</Radio>
							<Radio value='运维'>运维</Radio>
						</Radio.Group>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(AccountInfoEditModal);
