import React, { Component } from "react";
import { Button, Select, Table, Modal, Form, message, Col, Row, Spin, Breadcrumb, Tabs, Icon } from "antd";
import { Link, Switch, Route } from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons';
import "./DetailRecord.less"
import { createHashHistory } from "history";
import EnterAccounModal from "./EnterAccounModal";
import AccountPaymentModal from "./AccountPaymentModal";
import axios from "axios";
import qs from "qs";
import {connect} from "react-redux";

const { confirm } = Modal;

class DetailFinance extends Component {
    constructor(props){
        super(props);
        this.state = {
            dataSet: [],
            modal:null,
            smodal:null,
        }
    }

    componentDidMount() {
        this.fetchMsgList()
    }

    fetchMsgList = () => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token, match, shopId } = this.props;
        const body = {
            id:shopId,
            user_type:"admin"
        };
        axios
            .post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_caiwu_list&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    let data = res.data.data.content
                    this.setState({
                        dataSet:data
                    })
                }else{

                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    deleteConfirm = (id) => {
        let _this = this;
        confirm({
            title: '删除',
            content: '确定删除此日志吗',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                _this.delete(id)
            },
            onCancel() {

            },
        });
    };
    delete = (id) => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        const { token, shopId } = this.props
        const body = {
            shop_id:shopId,
            id:id
        }
        axios
            .post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_caiwu_del&token="+token, qs.stringify(body), config)
            .then(res => {
                if(res.data.response == 200){
                    message.success(res.data.msg);
                    this.fetchMsgList()
                }else{
                    Modal.warning({
                        title: '提示信息',
                        content: res.data.msg,
                        maskClosable:true
                    });
                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    addAccount = () => {
        const { token, match, shopId } = this.props;
        let modal = (
            <EnterAccounModal
                shopId={shopId}
                onHandleOk={() => {
                    this.setState({ visible: false });
                }}
                onHandleCancel={() => {
                    this.setState({ modal: null });
                }}
                onFetchMsgList={this.fetchMsgList}
            />
        );
        this.setState({ modal });
    };

    accountPayment = () => {
        const { token, match, shopId } = this.props;
        let smodal = (
            <AccountPaymentModal
                shopId={shopId}
                onHandleOk={() => {
                    this.setState({ visible: false });
                }}
                onHandleCancel={() => {
                    this.setState({ smodal: null });
                }}
                onFetchMsgList={this.fetchMsgList}
            />
        );
        this.setState({ smodal });
    }

    render() {
        const { match } = this.props;
        const { mode, dataSet } = this.state;
        const columns = [
            {
                title: "收入方式",
                dataIndex: "income_type",
                key: "income_type",
            },
            {
                title: "消费类型",
                dataIndex: "cost_type",
                key: "cost_type",

            },
            {
                title: "入账时间",
                dataIndex: "caiwu_time",
                key: "caiwu_time",
            },
            {
                title: "操作时间",
                dataIndex: "gmt_creat",
                key: "gmt_creat",
            },
            {
                title: "备注",
                dataIndex: "remark",
                key: "remark",
            },
            {
                title: "金额",
                dataIndex: "caiwu_money",
                key: "caiwu_money",
            },
            {
                title: "余额",
                dataIndex: "left_money",
                key: "left_money",
            },
            {
                title: "录入人员",
                dataIndex: "input_user",
                key: "input_user",
            },
            {
                title: "操作",
                dataIndex: "operate",
                render: (text, record) => (
                    <span onClick={this.deleteConfirm.bind(this,record.id)} style={{cursor:"pointer",color:'#008EEC'}}>
                        删除
                    </span>
                ),
            },
        ];
        return (
            <div className="student-signed-class">
                <div className="signed-header" style={{ overflow: "hidden" }}>
                    <Button type="primary" onClick={this.addAccount} style={{marginRight:10}}>
                        财务入账
                    </Button>
                    <Button type="primary" onClick={this.accountPayment} >
                        财务扣款
                    </Button>
                </div>
                <Table
                    dataSource={dataSet}
                    columns={columns}
                    bordered
                    pagination={false}
                />
                {this.state.modal}
                {this.state.smodal}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
});
export default connect(mapStateToProps)(DetailFinance);
