import React, { Component } from 'react'
import { Input } from 'antd';
import './SearchBar.less'
import PropTypes from "prop-types";


const { Search } = Input;


export default class SearchBar extends Component{
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSearch = this.onSearch.bind(this)
    }
    state = {
        label: '',
        placeholder: '',
    };
    static propTypes = {
        onSearchTextInput: PropTypes.func,
        onClick: PropTypes.func,
    };

    componentDidMount() {
        this.setState({
            label: this.props.label,
            placeholder: this.props.placeholder,
        })
    }

    onChange = e => {
        this.props.onSearchTextInput(e.target.value);
    };
    onSearch = e => {
        this.props.onClick();
    }

    render() {
        const { searchText } = this.props;
        const { label, placeholder } = this.state;

        return(
            <div className="xm-search-bar xm-search-bar-select">
                <div className="label"> {label}：</div>
                <Search
                    placeholder={placeholder}
                    onSearch={this.onSearch}
                    value={searchText}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}
