const initialState = {
	menuFold: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case "MENU_FOLD":
			return {
				...state,
				menuFold: true,
			};
		case "MENU_UNFOLD":
			return {
				...state,
				menuFold: false,
			};
		default:
			return state;
	}
}
