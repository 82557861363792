import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Form, Input, Table, InputNumber, Radio, message } from "antd";
import axios from "axios";
import qs from "qs";
import ImgPicker from "../../common/ImgPicker";
import "../../../modules/show/EditPage.less";
import E from 'wangeditor'
import _ from 'underscore';
import WangEditor from "./WangEditor";

const { TextArea } = Input;
let scrollTime = null;
export class TemplateEdit extends Component {
    state = {
        id:'',
        title:'',
        cover:'',
        summary:'',
        content:'',
        visible:false
    };

    componentDidMount() {

        if (this.props.currentId) {
            this.GetTemplateContent(this.props.currentId);
        }else{
            this.setState({
                visible:true
            })
        }

        /*let that = this;
        this.editor = new E('#editor');
        this.editor.customConfig.customUploadImg = function (files, insert) {
            // Headers
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            const { token } = that.props;
            const formData = new FormData();
            // // 添加要上传的文件
            formData.append('file', files[0]);
            axios
                .post("https://api.sanfeng9.com/admin/main.php?cmd=img_upload&token="+token, formData, config)
                .then(res => {
                    if(res.data.response == 200){
                        insert(res.data.img_url)
                    }else{

                    }
                })
                .catch(err => {
                    console.log(err)
                });

        }
        this.editor.create();
        let data = this.state.content;
        this.editor.txt.html(data)*/
    }

    GetTemplateContent(id) {
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const token = this.props.token;
        const body = {
            user_type: "admin",
            id: id,
        };
        axios
            .post("https://api.sanfeng9.com/shop/weixin.php?cmd=wx_msg_template&token=" + token, qs.stringify(body), config)
            .then((res) => {
                if (res.data.response === "200") {
                    let info = res.data.msg[0];
                    console.log(info)
                    this.setState({
                        visible:true,
                        id:info.id,
                        title:info.name,
                        cover:info.cover,
                        summary:info.summary,
                        content:info.content,
                    })
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    linkState = (e) => {
        this.setState({
            title:e.target.value
        })
    };

    handleSave = () => {
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const { token } = this.props;
        let {
            id, title, cover, summary, content
        } = this.state;

        let body = {
            user_type:'admin',
            id,
            name:title,
            cover,
            summary,
            content:content.replace(
                /class=/g,
                "classes="
            )
                .replace(
                    /\\\\\\/g,
                    ""
                )
                .replace(
                    /&quot;/g,
                    ""
                )
                .replace(
                    /microsoft/g,
                    ""
                )
                .replace(
                    /helvetica/g,
                    ""
                )
                .replace(
                    /microsoft/g,
                    ""
                )
                .replace(
                    /helvetica/g,
                    ""
                )
                .replace(
                    /neue/g,
                    ""
                )
                .replace(
                    /blinkmacsystemfont/g,
                    ""
                )
                .replace(
                    /word-spacing:/g,
                    ""
                )
                .replace(
                    /-webkit-text-stroke-width:=/g,
                    ""
                )
                .replace(
                    /orphans:=/g,
                    ""
                )
                .replace(
                    /pingfang/g,
                    ""
                )
                .replace(
                    /hiragino/g,
                    ""
                )
                .replace(
                    /sans=/g,
                    ""
                )
                .replace(
                    /sans-serif;/g,
                    ""
                )
                .replace(
                    /font-variant:=/g,
                    ""
                )
                .replace(
                    /for=/g,
                    ""
                )
                .replace(
                    /&quot;/g,
                    ""
                )
                .replace(
                    /letter-spacing/g,
                    ""
                )
                .replace(
                    /-apple-system/g,
                    ""
                )
                .replace(
                    /roboto/g,
                    ""
                )
                .replace(
                    /font-weight/g,
                    ""
                )
                .replace(
                    /text-indent/g,
                    ""
                )
                .replace(
                    /number/g,
                    ""
                )
                .replace(
                    /letter-spacing/g,
                    ""
                )
                .replace(
                    /letter-spacing:/g,
                    ""
                )
                .replace(
                    /white-space:/g,
                    ""
                )
                .replace(
                    /arial/g,
                    ""
                )
                .replace(
                    /widows/g,
                    ""
                )
                .replace(
                    /="\\"/g,
                    ""
                )
                .replace(
                    /segoe/g,
                    ""
                )
                .replace(
                    /yahei/g,
                    ""
                )
                .replace(/\\/g, "")
                .replace(/=""/g, "")
                .replace(
                    /auto/g,
                    ""
                )
                .replace(
                    /normal/g,
                    ""
                )
                .replace(
                    /text-transform/g,
                    ""
                ),
        };
        axios
            .post("https://api.sanfeng9.com/shop/weixin.php?cmd=template_insert&token=" + token, qs.stringify(body), config)
            .then((res) => {
                if (res.data.response === "200") {
                    message.success(res.data.msg);

                    this.props.handleEdit("");
                } else {
                    message.info(res.data.msg);
                }
            });
    };

    render() {
        const formItemLayout = {
            labelCol: { span: 2 },
            wrapperCol: { span: 20 },
        };

        return (
            <div className='box-body'>
                <Form {...formItemLayout}>
                    <Form.Item label="标题">
                        <Input
                            type="text"
                            id="msg_title"
                            placeholder="40字以内"
                            name="data.title"
                            value={this.state.title?this.state.title:""}
                            onChange={this.linkState}
                            className="form-control"
                            style={{width: "400px"}}
                            maxLength={40}
                        />
                    </Form.Item>
                    <Form.Item label="封面">
                        <ImgPicker
                            flag='cover'
                            imgdata={this.state.cover}
                            c_width={200}
                            c_height={200}
                            SetImg={(value) =>
                                this.setState({
                                    cover: value,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item label="简介">
                        <TextArea
                            placeholder='请输入简介'
                            autoComplete='off'
                            maxLength={200}
                            style={{ width: 400 }}
                            rows={3}
                            value={this.state.summary}
                            onChange={(e) =>
                                this.setState({
                                    summary: e.target.value,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item label="内容">
                        {
                            this.state.visible && <WangEditor
                                content={this.state.content}
                                setContent={(content) => {
                                    this.setState({
                                        content
                                    });
                                }}
                            />
                        }

                    </Form.Item>
                    <Row>
                        <Col span={20} push={2}>
                            <Button type='primary' onClick={this.handleSave}>
                                保存
                            </Button>
                            <Button style={{ marginLeft: "8px" }} onClick={() => this.props.handleEdit("")}>
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
});
export default connect(mapStateToProps)(TemplateEdit);
