import React, { Component, Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Header from "./Header";
import Nav from "./Nav";
import Index from "./Index";
import Help from "./Help";
import WxCreate from "./WxCreate";
import ManagerSetting from "../page/ManagerSetting/ManagerList";
import UserInfo from "./UserInfo";
import OrganizationManage from "../../modules/organization/OrganizationManage";
import OrganizationLog from "../../modules/organization/OrganizationLog";
import FinanceManage from "../../modules/finance/FinanceManage";
import OperationLog from "./OperationLog";
import Contact from "../../modules/contactInfo/index";
import App from "../../modules/app/index";

import PrivateRoute from "../common/PrivateRoute";

export class Home extends Component {
  static propTypes = {
    menuFold: PropTypes.bool,
  };
  render() {
    const { location, match } = this.props;
    const studentKey = location.pathname.split("/").length;
    return (
      <Fragment>
        <div id="home" style={{ height: "100%", position: "relative" }}>
          <Header action={this.props} />
          <Nav action={this.props} />
          <div className="right-container has-nav">
            <Switch>
              <PrivateRoute exact path={`${match.url}home`} component={Index} />
              <PrivateRoute path={`${match.url}help`} component={Help} />
              <PrivateRoute
                exact
                path={`${match.url}wx_create`}
                component={WxCreate}
              />
              <PrivateRoute
                path={`${match.url}manager_setting`}
                component={ManagerSetting}
              />
              <PrivateRoute
                exact
                path={`${match.url}user_info`}
                component={UserInfo}
              />
              <Route
                key={studentKey}
                path={`${match.url}integrated_query`}
                component={OrganizationManage}
              ></Route>
              <Route
                key={studentKey}
                path={`${match.url}shop_log`}
                component={OrganizationLog}
              ></Route>
              <Route
                key={studentKey}
                path={`${match.url}selling_manager`}
                component={FinanceManage}
              ></Route>
              <Route
                exact
                path={`${match.url}system_log`}
                component={OperationLog}
              ></Route>
              <Route
                exact
                path={`${match.url}contact`}
                component={Contact}
              ></Route>
              <Route exact path={`${match.url}app`} component={App}></Route>
              <Redirect to={`${match.url}home`} /> {/* 預設頁面 */}
            </Switch>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  menuFold: state.menu.menuFold,
});

export default connect(mapStateToProps)(Home);
