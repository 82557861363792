import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import E from "wangeditor";
import axios from "axios";

export class WangEditor extends Component {
    state = {
        editorContent: "",
        editor: "",
    };
    SetEditor = () => {};
    componentDidMount() {
        let that = this;
        this.editor = new E('#editor');
        this.editor.customConfig.customUploadImg = function (files, insert) {
            // Headers
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            const { token } = that.props;
            const formData = new FormData();
            // // 添加要上传的文件
            formData.append('file', files[0]);
            formData.append('user_type', 'admin');
            axios
                .post("https://api.sanfeng9.com/shop/main.php?cmd=img_upload&token="+token, formData, config)
                .then(res => {
                    if(res.data.response == 200){
                        insert(res.data.img_url)
                    }else{

                    }
                })
                .catch(err => {
                    console.log(err)
                });

        }

        this.editor.customConfig.onchange = (html) => {
            // this.setState({
            // 	editorContent: html,
            // });
            this.props.setContent(html);
        };

        this.editor.create();
        this.editor.txt.html(this.props.content);
    }

    render() {
        return (
            <Fragment>
                <div id="editor"></div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
});
export default connect(mapStateToProps)(WangEditor);
