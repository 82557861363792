import React, { Component } from "react";
import { Button, Input, Select, Table, Modal, Form, message, Col, Row, Spin, Breadcrumb, Tabs, Icon } from "antd";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import DetailBranch from "./DetailBranch";
import DetailFinance from "./DetailFinance";
import DetailRecharge from "./DetailRecharge";
import DetailConsume from "./DetailConsume";
import "./OrganizationManageDetail.less";
import { createHashHistory } from "history";
import axios from "axios";
import qs from "qs";
import { connect } from "react-redux";
import OrganizationEditModal from "./OrganizationEditModal";

const hashHistory = createHashHistory();
const { TabPane } = Tabs;

class OrganizationManageDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			activeKey: "branch",
			studentInfo: {},
			manageData: [],
			manageName: "",
		};
	}

	componentDidMount() {
		const { location, match } = this.props;
		if (location.pathname === `/integrated_query/detail/${match.params.id}/branch`) {
			this.setState({
				activeKey: "branch",
			});
		}
		if (location.pathname === `/integrated_query/detail/${match.params.id}/finance`) {
			this.setState({
				activeKey: "finance",
			});
		}
		if (location.pathname === `/integrated_query/detail/${match.params.id}/recharge`) {
			this.setState({
				activeKey: "recharge",
			});
		}
		if (location.pathname === `/integrated_query/detail/${match.params.id}/consume`) {
			this.setState({
				activeKey: "consume",
			});
		}
		this.fetchMsgList();
	}

	tabs = (key) => {
		this.setState({
			activeKey: key,
		});
		const { match } = this.props;
		hashHistory.push(`${match.url}/${key}`);
	};

	fetchMsgList = () => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token, match } = this.props;
		const body = {
			id: match.params.id,
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_list&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					let data = res.data.data.content[0];
					this.setState(
						{
							studentInfo: data,
							totalCount: res.data.data.allcount,
						},
						() => {
							this.getManageData();
						}
					);
				} else {
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	getManageData = () => {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const token = this.props.token;
		axios
			.post("https://api.sanfeng9.com/admin/admin.php?cmd=admin_list&token=" + token, "user_type=admin", config)
			.then((res) => {
				const data = res.data.data.content.map((item) => ({
					key: item.id,
					id: item.id,
					name: item.realname,
					activeTime: item.last_time,
					ip: item.last_ip,
					ipAddr: item.ip_addr,
					group: item.groupname,
					phone: item.phone,
					qq: item.qq,
					superAdmin: item.super_admin,
				})); /*.filter((item)=>{
                    return item.group === '销售'
                });*/
				this.setState({
					manageData: data,
				});
				if (this.state.studentInfo.sale_user_id) {
					let sale_user_id = data.find((item) => item.id == this.state.studentInfo.sale_user_id);
					if (sale_user_id) {
						this.setState({
							manageName: sale_user_id.name,
						});
					}
				}
			});
	};

	handleEdit = () => {
		let modal = (
			<OrganizationEditModal
				isEdit={true}
				info={this.state.studentInfo}
				onHandleOk={() => {
					this.setState({ visible: false });
				}}
				onHandleCancel={() => {
					this.setState({ modal: null });
				}}
				onFetchMsgList={this.fetchMsgList}
			/>
		);
		this.setState({ modal });
	};

	login = () => {
		// Headers
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};
		const { token, match } = this.props;
		const body = {
			shop_id: match.params.id,
			user_type: "admin",
		};
		axios
			.post("https://api.sanfeng9.com/admin/shop.php?cmd=shop_login&token=" + token, qs.stringify(body), config)
			.then((res) => {
				if (res.data.response == 200) {
					window.open("https://c.sanfeng9.com/#/login?token=" + token);
				} else {
					message.error(res.data.msg);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render() {
		const { match } = this.props;
		const { loading, activeKey, studentInfo, modal } = this.state;
		let tag;
		if (studentInfo.shop_level == "100") {
			tag = (
				<span
					className='tag'
					style={{
						color: "#008eec",
						borderColor: "#008eec",
					}}>
					单门店版
				</span>
			);
		} else if (studentInfo.shop_level == "10") {
			tag = (
				<span
					className='tag'
					style={{
						color: "rgb(191, 191, 191)",
						borderColor: "rgb(191, 191, 191)",
					}}>
					永久免费版
				</span>
			);
		} else if (studentInfo.shop_level == "500") {
			tag = (
				<span
					className='tag'
					style={{
						color: "red",
						borderColor: "red",
					}}>
					五门店版
				</span>
			);
		} else {
			tag = (
				<span
					className='tag'
					style={{
						color: "#ff7519",
						borderColor: "#ff7519",
					}}>
					不限门店版
				</span>
			);
		}
		return (
			<div className='page'>
				<div className='page-content' style={{ height: "100%" }}>
					<Spin spinning={loading}>
						<div className='xm-breadCrumb'>
							<span className='back-btn' onClick={() => hashHistory.go(-1)}>
								<LeftOutlined />
								返回
							</span>
							<div className='divide'></div>
							<Breadcrumb>
								<Breadcrumb.Item>商户详情</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div
							className='box student-base-info'
							style={{
								margin: "0px 24px 24px",
								paddingTop: "30px",
							}}>
							<Row type='flex'>
								<Col span={12}>
									<div className='student-basic'>
										ID：
										<span className='name'>{studentInfo.id}</span>
										名称：
										<span className='name'>{studentInfo.name}</span>
										<span
											onClick={this.handleEdit}
											style={{ fontSize: 12, color: "#28adfa", marginRight: 10, cursor: "pointer" }}>
											编辑
										</span>
										<span className='student-tags'>{tag}</span>
									</div>
									{/*<div className='student-tags' style={{ fontSize: 12, marginTop: 10 }}>
										商户地址：{studentInfo.address}
									</div>*/}
								</Col>
								<Col span={12}>
									<div style={{ textAlign: "right" }}>
										<Button className='mr8' type='primary' onClick={this.login}>
											登录后台
										</Button>
									</div>
								</Col>
							</Row>
							<Row style={{ marginTop: "20px" }}>
								<Col span={6}>
									<Form>
										<Form.Item>
											<span className='form-item-label'>联系电话：</span>
											<span>{studentInfo.phone}</span>
										</Form.Item>
										<Form.Item>
											<span className='form-item-label'>开通时间：</span>
											<span>{studentInfo.gmt_creat}</span>
										</Form.Item>
									</Form>
								</Col>
								<Col span={6}>
									<Form>
										{/*<Form.Item>
											<span className='form-item-label'>客户经理：</span>
											<span>{this.state.manageName}</span>
										</Form.Item>*/}
										<Form.Item>
											<span className='form-item-label'>VIP到期时间：</span>
											<span>{studentInfo.end_time}</span>
										</Form.Item>
										<Form.Item>
											<span className='form-item-label'>开通IP：</span>
											<span>
												{studentInfo.reg_ip}
												{studentInfo.reg_ip_addr && <span>（{studentInfo.reg_ip_addr}）</span>}
											</span>
										</Form.Item>
									</Form>
								</Col>
								<Col span={6}>
									<Form>
										{/*<Form.Item>
											<span className='form-item-label'>提成：</span>
											<span>{studentInfo.ticheng}</span>
										</Form.Item>*/}
										<Form.Item>
											<span className='form-item-label'>最后登录IP：</span>
											<span>
												{studentInfo.laston_ip}
												{studentInfo.laston_ip_addr && <span>（{studentInfo.laston_ip_addr}）</span>}
											</span>
										</Form.Item>
										<Form.Item>
											<span className='form-item-label'>最近登录：</span>
											<span>{studentInfo.laston_time}</span>
										</Form.Item>
									</Form>
								</Col>
								<Col span={6}>
									<Form></Form>
								</Col>
							</Row>
						</div>
						<div className='content-body box'>
							<Tabs activeKey={activeKey} onChange={this.tabs}>
								<TabPane tab='商户门店' key='branch'></TabPane>
								<TabPane tab='财务明细' key='finance'></TabPane>
								{/* <TabPane tab='短信充值' key='recharge'></TabPane>
								<TabPane tab='短信消费' key='consume'></TabPane> */}
							</Tabs>
							<Switch>
								<Route
									key='branch'
									path={`${match.url}/branch`}
									render={() => <DetailBranch shopId={match.params.id} />}
								/>
								<Route
									key='finance'
									path={`${match.url}/finance`}
									render={() => <DetailFinance shopId={match.params.id} />}
								/>
								<Route
									key='recharge'
									path={`${match.url}/recharge`}
									render={() => <DetailRecharge shopId={match.params.id} />}
								/>
								<Route
									key='consume'
									path={`${match.url}/consume`}
									render={() => <DetailConsume shopId={match.params.id} />}
								/>
								<Redirect to={`${match.url}/branch`} />
							</Switch>
						</div>
						{modal}
					</Spin>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(OrganizationManageDetail);
