import React, { Component } from "react";
import { connect } from "react-redux";

export class PermissionSet extends Component {
    render(){
        return(
            <div>权限设置</div>
        )
    }
}

const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps)(PermissionSet);